import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actionGetResponses } from "../../services/activities.services";
import {
  actionGetAllModules,
  actionGetModuleActivities,
} from "../../services/modulos.services";
import { ModuleType } from "../../types/ModuleTypes";

interface ModuleResponses {
  respuestas: object;
}

interface ModulosState {
  allModules: ModuleType[];
  moduleActivities: string[];
  moduleResponses: ModuleResponses | null | undefined;
  moduleReturnActivities: object | null | undefined | any;
  moduleCorrection: object | null | undefined | any;
}

const initialState: ModulosState = {
  allModules: [],
  moduleActivities: [],
  moduleResponses: null,
  moduleReturnActivities: null,
  moduleCorrection: null,
};

export const ModulosSlice = createSlice({
  name: "Modulos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(actionGetAllModules.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     allModules: action.payload,
    //   };
    // });
    // builder.addCase(actionGetModuleActivities.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     moduleActivities: action.payload,
    //   };
    // });
    // builder.addCase(actionGetResponses.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     moduleResponses:
    //       action.payload === undefined ? null : action.payload.respuestas,
    //     moduleReturnActivities: !action.payload?.devolucion
    //       ? null
    //       : action.payload?.devolucion,
    //     moduleCorrection: !action.payload?.correccion
    //       ? null
    //       : action.payload?.correccion,
    //   };
    // });
  },
});

export default ModulosSlice.reducer;
