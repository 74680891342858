import React from "react";
import { FaBars } from "react-icons/fa";
import { Route, Routes } from "react-router-dom";
import AdminHome from "../screens/Admin/AdminHome/AdminHome";
import AdminSidebar from "../screens/Admin/AdminSidebar/AdminSidebar";
import CreateCourse from "../screens/Admin/CreateCourse/CreateCourse";
import CreateModule from "../screens/Admin/CreateModule/CreateModule";
import CreateUsers from "../screens/Admin/CreateUsers/CreateUsers";
import QuestionModules from "../screens/Admin/Questions/QuestionsModules";
import QuestionsResponse from "../screens/Admin/Questions/QuestionsResponses";
import UploadDocuments from "../screens/Admin/UploadDocuments/UploadDocuments";
import UserActivities from "../screens/Admin/UserActivities/UserActivities";
import UserCourses from "../screens/Admin/UserCourses/UserCourses";
import UserModules from "../screens/Admin/UserModules/UserModules";
import UsersList from "../screens/Admin/UsersList/UsersList";

export const AdminRoutes = () => {
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

	return (
		<>
			<AdminSidebar
				setIsSidebarOpen={() => setIsSidebarOpen(false)}
				isSidebarOpen={isSidebarOpen}
			/>
			<div className="h-16 bg-green-300 fixed w-full z-10 flex items-center shadow-lg shadow-green-500/30">
				<button
					onClick={() => setIsSidebarOpen(true)}
					className={`${
						isSidebarOpen
							? "-translate-x-8"
							: "translate-x-0"
					} top-4 transition transform ease-linear duration-500 text-gray-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-gray-300 focus:outline-none ml-6 hover:bg-gray-200 hover:text-gray-800`}
				>
					<FaBars className="w-6 h-6" />
				</button>
			</div>
			<Routes>
				<Route path="/home" element={<AdminHome />} />
				<Route
					path="/create-course"
					element={<CreateCourse />}
				/>
				<Route
					path="/create-module"
					element={<CreateModule />}
				/>
				<Route
					path="/upload-documents"
					element={<UploadDocuments />}
				/>
				<Route
					path="/question-modules"
					element={<QuestionModules />}
				/>
				<Route
					path="/question-modules/:moduleId"
					element={<QuestionsResponse />}
				/>
				<Route path="/users" element={<CreateUsers />} />
				<Route
					path="/users-activities"
					element={<UsersList />}
				/>
				<Route
					path="/users-activities/:userId/cursos"
					element={<UserCourses />}
				/>
				<Route
					path="/users-activities/:userId/cursos/:idCurso/modules"
					element={<UserModules />}
				/>
				<Route
					path="/users-activities/:userId/cursos/:idCurso/modules/:moduleId"
					element={<UserActivities />}
				/>
			</Routes>
		</>
	);
};
