import React from "react";
import { useNavigate } from "react-router-dom";

const AdminHome = () => {
	const navigation = useNavigate();

	return (
		<div className="h-screen w-screen flex flex-col items-center justify-evenly">
			<div className="flex w-3/4 justify-around">
				<button
					onClick={() =>
						navigation("/admin/users-activities")
					}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex justify-center items-center"
				>
					<span className="font-bold text-xl">
						Lista de usuarios
					</span>
				</button>
				<button
					onClick={() => navigation("/admin/question-modules")}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex flex-col justify-center items-center px-5"
				>
					<span className="font-bold text-xl">
						Responder Preguntas
					</span>
				</button>
				{/* <button
					onClick={() => navigation("/admin/users")}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex justify-center items-center"
				>
					<span className="font-bold text-xl">
						Crear usuarios
					</span>
				</button> */}
			</div>
			<div className="flex w-3/4 justify-around">
				<button
					onClick={() => navigation("/admin/create-course")}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex justify-center items-center"
				>
					<span className="font-bold text-xl">
						Crear Curso
					</span>
				</button>
				<button
					onClick={() => navigation("/admin/upload-documents")}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex justify-center items-center"
				>
					<span className="font-bold text-xl">
						Subir Documento
					</span>
				</button>
				<button
					onClick={() => navigation("/admin/create-module")}
					className="w-52 h-52 bg-green-100 rounded-xl shadow-lg flex justify-center items-center"
				>
					<span className="font-bold text-xl">
						Crear Modulo
					</span>
				</button>
			</div>
		</div>
	);
};

export default AdminHome;
