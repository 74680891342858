import { configureStore } from "@reduxjs/toolkit";
import allUserSlice from "./slices/allUser.slice";
import modulesSlice from "./slices/modules.slice";
import userSlice from "./slices/user.slice";
// ...

export const store = configureStore({
	reducer: {
		modulosReducer: modulesSlice,
		userReducer: userSlice,
		allUserReducer: allUserSlice,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
