import React from "react";
import { Route, Routes } from "react-router-dom";
import Actividades from "../screens/Actividades/Actividades";
import CreateCourse from "../screens/Admin/CreateCourse/CreateCourse";
import CreateModule from "../screens/Admin/CreateModule/CreateModule";
import CreateUsers from "../screens/Admin/CreateUsers/CreateUsers";
import UserActivities from "../screens/Admin/UserActivities/UserActivities";
import UserCourses from "../screens/Admin/UserCourses/UserCourses";
import UserModules from "../screens/Admin/UserModules/UserModules";
import UsersList from "../screens/Admin/UsersList/UsersList";
import Courses from "../screens/Courses/Courses";
import Home from "../screens/Home/Home";
import Sidebar from "../screens/Home/sidebar/Sidebar";
import Modules from "../screens/Modules/Modules";
import OneModule from "../screens/Modules/OneModule/OneModule";

import { FaBars } from "react-icons/fa";
import CoursePresentation from "../screens/Courses/CoursePresentation";
import ModuleQuestions from "../screens/Modules/OneModule/ModuleQuestions";
import CourseDocuments from "../screens/Courses/CourseDocuments";

export const UserRoutes = () => {

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  return (
    <>
      <Sidebar
        setIsSidebarOpen={() => setIsSidebarOpen(false)}
        isSidebarOpen={isSidebarOpen}
      />
      <div className="h-16 bg-green-300 fixed w-full z-10 flex items-center shadow-lg shadow-green-500/30">
        <button
          onClick={() => setIsSidebarOpen(true)}
          className={`${
            isSidebarOpen ? "-translate-x-8" : "translate-x-0"
          } top-4 transition transform ease-linear duration-500 text-gray-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-gray-300 focus:outline-none ml-6 hover:bg-gray-200 hover:text-gray-800`}
        >
          <FaBars className="w-6 h-6" />
        </button>
      </div>
      <Routes>
        <Route path="/inicio" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/:courseId" element={<CoursePresentation />} />
        <Route path="/:courseId/modulos" element={<Modules />} />
        <Route path="/:courseId/documentos" element={<CourseDocuments />} />
        <Route path="/:courseId/:moduleId/modulo" element={<OneModule />} />
        <Route
          path="/:courseId/:moduleId/actividades"
          element={<Actividades />}
        />
        <Route
          path="/:courseId/:moduleId/preguntas"
          element={<ModuleQuestions />}
        />
      </Routes>
    </>
  );
};
