import { getAuth, onAuthStateChanged } from "firebase/auth";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const PublicRoutes = ({ children }: { children?: any }) => {
  const userData: any = localStorage.getItem("user");
  const userId: any = JSON.parse(userData)?.uid;
  const userAdmin: any = localStorage.getItem("isAdmin");
  const isUserAdmin: any = JSON.parse(userAdmin)?.isAdmin;
  const isAuthenticated = userId;

  if (!isAuthenticated) {
    return children;
  } else if (isUserAdmin) {
    return <Navigate to={"/admin/users-activities"} />;
  } else {
    return <Navigate to={"/inicio"} />;
  }
};
