import React from "react";
import MainRouter from "./router/MainRouter";

const App = () => {

	return (
		<>
			<MainRouter />
		</>
	);
};

export default App;
