import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
	actionActivateCourse,
	actionDeactivateCourse,
	actionGetAllCourses,
} from "../../../services/courses.services";
import { actionGetUserCourses } from "../../../services/users.services";
import { FaPlusCircle, FaMinusCircle, FaArrowRight } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";

interface userCourses {
	courseId: string;
	courseName: string;
}
interface courses {
	courseId: string;
	courseName: string;
	courseImage: string;
	courseDescription: string;
}

interface allCourses {
	courseId: string;
	courseName: string;
}

const UserCourses = () => {
	const params = useParams();
	const navigation = useNavigate();
	const dispatch = useAppDispatch();

	const [userCourses, setUserCourses] = React.useState<userCourses[]>([]);
	const [allCourses, setAllCourses] = React.useState<allCourses[]>([]);
	const [refreshPage, setRefreshPage] = React.useState<string>("");

	React.useEffect(() => {
		Promise.all([
			dispatch(actionGetUserCourses(params.userId)),
			dispatch(actionGetAllCourses()),
		]).then((promiseResponse) => {
			setUserCourses(promiseResponse[0].payload);
			let courses = promiseResponse[1].payload;
			promiseResponse[0].payload.map((userCourse: any) => {
				courses = courses.filter(
					(course: any) =>
						course.courseId !== userCourse.courseId
				);
			});
			setAllCourses(courses);
		});
	}, []);

	const handleActivateCourse = ({
		courseName,
		courseId,
		userId,
		courseDescription,
		courseImage,
	}: {
		courseName: string;
		courseId: string;
		userId: string | undefined;
		courseDescription: string;
		courseImage: string;
	}) => {
		setUserCourses([
			...userCourses,
			{ courseName: courseName, courseId },
		]);
		setAllCourses(
			allCourses.filter(
				(course: any) => course.courseId !== courseId
			)
		);
		dispatch(
			actionActivateCourse({
				userId,
				courseId,
				courseName,
				courseDescription,
				courseImage,
			})
		);
		setRefreshPage(Math.random().toString());
	};

	const handleDeactivateCourse = ({
		userId,
		courseId,
		courseName,
	}: {
		userId: string | undefined;
		courseId: string;
		courseName: string;
	}) => {
		setUserCourses(
			userCourses.filter(
				(course: any) => course.courseId !== courseId
			)
		);
		setAllCourses([
			...allCourses,
			{ courseId: courseId, courseName: courseName },
		]);
		dispatch(
			actionDeactivateCourse({
				userId: userId,
				courseId: courseId,
			})
		);
		setRefreshPage(Math.random().toString());
	};

	return (
		<div className="pt-20 pb-20">
			<span className="flex justify-center font-black text-cyan-700 text-4xl mb-5">
				Cursos habilitados
			</span>
			{userCourses.length > 0 || allCourses.length > 0 ? (
				<div className="pl-12 pr-12">
					<div className="flex flex-row-reverse justify-center">
						{userCourses.map((course: any, index) => (
							<div key={index} className="mx-10">
								<div className="flex justify-center">
									<div
										key={index}
										className="bg-white w-60 rounded-xl shadow-lg"
										onClick={() =>
											navigation(
												`/admin/users-activities/${params.userId}/cursos/${course.courseId}/modules`
											)
										}
									>
										<div className="">
											<img
												src={
													course.courseImage
												}
												alt={
													course.courseName
												}
												className="w-full rounded-t-xl object-cover"
											/>
										</div>
										<div className="p-3 flex flex-col justify-between">
											<h2 className="font-bold text-3xl text-center">
												{
													course.courseName
												}
											</h2>
											<p className="text-lg text-center">
												{
													course.courseDescription
												}
											</p>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center mt-5">
									<button
										onClick={() =>
											handleDeactivateCourse(
												{
													userId: params.userId,
													courseId: course.courseId,
													courseName:
														course.courseName,
												}
											)
										}
										className="bg-red-300 py-4 px-6 rounded-md shadow-lg shadow-red-500/50 text-white font-bold text-xl"
									>
										<h1>Bloquear</h1>
									</button>
								</div>
							</div>
						))}
					</div>
					<span className="flex justify-center font-black text-red-700 text-4xl mb-5 mt-5">
						Cursos Deshabilitados
					</span>
					<div className="flex flex-row-reverse justify-center">
						{allCourses.map((course: any, index) => (
							<div key={index} className="mx-10">
								<div className="flex justify-center">
									<div
										key={index}
										className="bg-white w-60 rounded-xl shadow-lg"
									>
										<div className="">
											<img
												src={
													course.courseImage
												}
												alt={
													course.courseName
												}
												className="w-full rounded-t-xl object-cover"
											/>
										</div>
										<div className="p-3 flex flex-col justify-between">
											<h2 className="font-bold text-3xl text-center">
												{
													course.courseName
												}
											</h2>
											<p className="text-lg text-center">
												{
													course.courseDescription
												}
											</p>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center mt-5">
									<button
										onClick={() =>
											handleActivateCourse(
												{
													userId: params.userId,
													courseId: course.courseId,
													courseName:
														course.courseName,
													courseDescription:
														course.courseDescription,
													courseImage:
														course.courseImage,
												}
											)
										}
										className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
									>
										<h1>Habilitar</h1>
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="pt-40 flex justify-center items-center">
					<BounceLoader
						color={"#86EFAC"}
						loading={true}
						size={200}
					/>
				</div>
			)}
		</div>
	);
};

export default UserCourses;
