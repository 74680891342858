import React from "react";
import { FaUpload } from "react-icons/fa";
import { useAppDispatch } from "../../../redux/hooks";
import {
	actionGetAllDocumentsByCourse,
	actionCreateDocument,
} from "../../../services/documents.services";
import { GenerateUid } from "../../../utils/GenerateUid";

const UploadDocuments = () => {
	const dispatch = useAppDispatch();

	const [allDocuments, setAllDocuments] = React.useState([]);
	const [document, setDocument] = React.useState();
	const [documentName, setDocumentName] = React.useState("");
	const [fileName, setFileName] = React.useState("");
	const [refreshDocuments, setRefreshDocuments] = React.useState(0);

	React.useEffect(() => {
		dispatch(actionGetAllDocumentsByCourse()).then((allDoc) => {
			setAllDocuments(allDoc.payload);
		});
	}, []);

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);
	const handleClick = (event: any) => {
		hiddenFileInput.current!.click();
	};
	const handleChange = (e: any) => {
		setDocument(e.target.files[0]);
		setFileName(e.target.files[0].name);
	};

	const functionCreateDocument = () => {
        // setDocument(undefined);
        setFileName("");
        setDocumentName("");
		dispatch(
			actionCreateDocument({
				courseId: "1647607837012",
				documentFile: document,
				documentName: documentName,
			})
		).then((_) => {
			const documentId = GenerateUid();
			setTimeout(() => {
				dispatch(actionGetAllDocumentsByCourse()).then(
					(allDoc) => {
						setAllDocuments(allDoc.payload);
					}
				);
			}, 3000);
		});
	};

	return (
		<div className="pt-28">
			<div>
				<span className="flex justify-center font-black text-cyan-700 text-2xl mb-16">
					Documentos ya subidos
				</span>
				<div className="flex">
					{allDocuments.map((doc: any, index) => (
						<div key={index} className="pb-5">
							<div className="flex justify-center w-72">
								<div
									key={index}
									className="bg-white w-4/6 h-60 rounded-lg shadow-lg flex flex-col"
								>
									<div className="h-40">
										<img
											src={
												"https://i0.wp.com/www.fundacionttm.org/wp-content/uploads/2016/11/Documento-R.png?fit=810%2C810&ssl=1"
											}
											alt={
												"imagen de documento"
											}
											className="rounded-t-lg h-40 w-96 object-contain"
										/>
									</div>
									<div className="h-20 flex justify-center flex-col px-4">
										<h2 className="font-bold text-xl text-center">
											{doc.name}
										</h2>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<span className="flex justify-center font-black text-cyan-700 text-2xl mb-16">
					Subir nuevo documento
				</span>
				<div className="flex justify-center">
					<div className="w-3/6 mr-10 flex flex-col items-center">
						<input
							placeholder="Nombre para el documento"
							type={"text"}
							value={documentName}
							onChange={(e: any) =>
								setDocumentName(e.target.value)
							}
							className="border-blue-400 border-2 w-3/5 h-14 rounded-lg p-2 mb-4"
						/>
						<button
							onClick={handleClick}
							className="w-32 h-32 flex justify-center items-center hover:text-green-500"
						>
							<div className="flex flex-col justify-around items-center">
								<FaUpload size={50} />
								<span className="mt-5">
									Seleccionar un documento
								</span>
							</div>
						</button>
						{fileName && <div>{fileName}</div>}
					</div>
					<input
						type="file"
						ref={hiddenFileInput}
						onChange={handleChange}
						style={{ display: "none" }}
					/>
					<div className="flex justify-center items-center">
						<button
							onClick={() =>
								functionCreateDocument()
							}
							className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						>
							Subir documento
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadDocuments;
