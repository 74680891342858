import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actionGetAllUsers } from "../../services/users.services";

interface users {
    email: string;
    nombreCompleto: string;
    userId: string;
}

interface AllUserState {
  usersList: users[];
}

const initialState: AllUserState = {
  usersList: [],
};

export const allUserSlice = createSlice({
  name: "AllUserSlice",
  initialState,
  reducers: {
    actionTest: (state, action) => {}
    // actionIsAdmin: (state, action) => {
    //   return {
    //     ...state,
    //     isAdmin: action.payload,
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(actionGetAllUsers.fulfilled, (state, action) => {
      state.usersList = action.payload;
    });
  },
});

// export const { actionIsAdmin } = UserSlice.actions;

export default allUserSlice.reducer;
