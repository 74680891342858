import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";
import { ADMIN_LIST } from "../utils/ADMIN_LIST";

export const PrivateAdminRoutes = ({ children }: { children?: any }) => {
  const userData: any = localStorage.getItem("user");
  const userAdmin: any = localStorage.getItem("isAdmin");
  const userId: any = JSON.parse(userData)?.uid;
  const isUserAdmin: any = JSON.parse(userAdmin)?.isAdmin;

  const isAuthenticated = userId;
  const location = useLocation();
  const isAdmin = ADMIN_LIST.includes(userId);
  if (!isAdmin) {
    return <Navigate to="/inicio" replace />;
  }
  return children;
};
