import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  actionActivateModule,
  actionDeactivateModule,
  actionGetAllModulesByCourse,
} from "../../../services/modulos.services";
import { actionGetUserModules } from "../../../services/users.services";
import { FaPlusCircle, FaMinusCircle, FaArrowRight } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";

interface userModules {
  moduleId: string;
  moduleName: string;
}

interface allModules {
  moduleName: string;
  moduleId: string;
}

const UserModules = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigation = useNavigate();

  const [userModules, setUserModules] = React.useState<userModules[]>([]);
  const [allModules, setAllModules] = React.useState<allModules[]>([]);

  React.useEffect(() => {
    Promise.all([
      dispatch(
        actionGetUserModules({ userId: params.userId, idCurso: params.idCurso })
      ),
      dispatch(actionGetAllModulesByCourse(params.idCurso)),
    ]).then((promiseResponse) => {
      setUserModules(promiseResponse[0].payload);
      let modules = promiseResponse[1].payload;
      promiseResponse[0].payload.map((userModule: any) => {
        modules = modules.filter(
          (module: any) => module.moduleId !== userModule.moduleId
        );
      });
      setAllModules(modules);
    });
  }, []);

  const handleActivateModule = ({
    userId,
    courseId,
    moduleId,
    moduleName,
    moduleImage,
    moduleVideo,
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string;
    moduleName: string;
    moduleImage: string;
    moduleVideo: string;
  }) => {
    setUserModules([...userModules, { moduleName, moduleId }]);
    setAllModules(
      allModules.filter((module: any) => module.moduleId !== moduleId)
    );
    dispatch(
      actionActivateModule({
        userId,
        courseId,
        moduleId,
        moduleName,
        moduleImage,
        moduleVideo,
      })
    );
  };

  const handleDeactivateModule = ({
    userId,
    courseId,
    moduleId,
    moduleName,
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string;
    moduleName: string;
  }) => {
    setUserModules(userModules.filter((elem) => elem.moduleId !== moduleId));
    setAllModules([
      ...allModules,
      { moduleId: moduleId, moduleName: moduleName },
    ]);
    dispatch(actionDeactivateModule({ userId, courseId, moduleId }));
  };

  return (
    <div className="pt-20 pb-20">
      {userModules.length > 0 || allModules.length > 0 ? (
        <div className="pl-12 pr-12">
          <span className="flex justify-center font-black text-cyan-700 text-4xl mb-5 mt-5">
            Modulos Habilitados
          </span>
          <div className="flex flex-row-reverse justify-center">
            {userModules.map((modulo: any, index) => (
              <div key={index} className="mx-10">
                <div className="flex justify-center">
                  <div
                    key={index}
                    className="bg-white w-60 rounded-xl shadow-lg"
                    onClick={() =>
                      navigation(
                        `/admin/users-activities/${params.userId}/cursos/${params.idCurso}/modules/${modulo.moduleId}`
                      )
                    }
                  >
                    <div className="">
                      <img
                        src={modulo.moduleImage}
                        alt={modulo.moduleName}
                        className="w-full rounded-t-xl object-cover"
                      />
                    </div>
                    <div className="p-3 flex flex-col justify-between">
                      <h2 className="font-bold text-3xl text-center">
                        {modulo.moduleName}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-5">
                  <button
                    onClick={() =>
                      handleDeactivateModule({
                        userId: params.userId,
                        courseId: params.idCurso,
                        moduleId: modulo.moduleId,
                        moduleName: modulo.moduleName,
                      })
                    }
                    className="bg-red-300 py-4 px-6 rounded-md shadow-lg shadow-red-500/50 text-white font-bold text-xl"
                  >
                    <h1>Bloquear</h1>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <span className="flex justify-center font-black text-red-700 text-4xl mb-5 mt-5">
            Modulos Deshabilitados
          </span>
          <div className="flex flex-row-reverse justify-center">
            {allModules.map((modulo: any, index) => (
              <div key={index} className="mx-10">
                <div className="flex justify-center">
                  <div
                    key={index}
                    className="bg-white w-60 rounded-xl shadow-lg"
                  >
                    <div className="">
                      <img
                        src={modulo.moduleImage}
                        alt={modulo.moduleName}
                        className="w-full rounded-t-xl object-cover"
                      />
                    </div>
                    <div className="p-3 flex flex-col justify-between">
                      <h2 className="font-bold text-3xl text-center">
                        {modulo.moduleName}
                      </h2>
                      <p className="text-lg text-center">
                        {modulo.moduleDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-5">
                  <button
                    onClick={() =>
                      handleActivateModule({
                        userId: params.userId,
                        courseId: params.idCurso,
                        moduleId: modulo.moduleId,
                        moduleName: modulo.moduleName,
                        moduleImage: modulo.moduleImage,
                        moduleVideo: modulo.moduleVideo,
                      })
                    }
                    className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                  >
                    <h1>Habilitar</h1>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="pt-40 flex justify-center items-center">
          <BounceLoader color={"#86EFAC"} loading={true} size={200} />
        </div>
      )}
    </div>
  );
};

export default UserModules;
