import React from "react";
import Sidebar from "./sidebar/Sidebar";
import { FaBars } from "react-icons/fa";
import { getAuth, signOut } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { actionGetUserCourses } from "../../services/users.services";
import BounceLoader from "react-spinners/BounceLoader";
import Slider from "react-slick";

const Home = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const userData: any = localStorage.getItem("user");
  const userId: any = JSON.parse(userData)?.uid;

  const [userCourses, setUserCourses] = React.useState<any>([]);

  React.useEffect(() => {
    /* TRAER TODOS LOS CURSOS */
    dispatch(actionGetUserCourses(userId)).then((responseUserCourses) => {
      setUserCourses(responseUserCourses.payload);
    });
  }, []);

  const { isAdmin } = useAppSelector((state) => state.userReducer);

  const deleteLocalStorage = () => {
    localStorage.removeItem("user");
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  };


  return (
    <div>
      <div className="pt-20">
        <span className="flex justify-center font-black text-cyan-700 text-4xl mb-5">
          Mis cursos
        </span>
        {userCourses.length > 0 ? (
          userCourses.map((course: any, index: number) => (
            <div key={index} className="pb-5">
              <div className="flex justify-center">
                <div
                  key={index}
                  className="bg-white w-5/6 md:w-60 h-auto rounded-xl shadow-lg"
                  onClick={() => navigation(`/${course.courseId}`)}
                >
                  <div className="">
                    <img
                      src={course.courseImage}
                      alt={course.courseName}
                      className="w-full rounded-t-xl object-cover md:w-60"
                    />
                  </div>
                  <div className="p-3 flex flex-col justify-between">
                    <h2 className="font-bold text-3xl text-center">
                      {course.courseName}
                    </h2>
                    <p className="text-lg text-center">
                      {course.courseDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-20 flex justify-center items-center">
            <BounceLoader
              color={"#86EFAC"}
              loading={true}
              size={200}
            />
          </div>
        )}
        {/* //TODO: Slider a futuro */}
        {/* {userCourses.map((course: any, index: number) => (
          <div key={index} className="pb-5">
            <div className="flex justify-center ">
              <div
                key={index}
                className="bg-white w-5/6 h-80 rounded-xl shadow-lg md:flex"
                onClick={() =>
                  navigation(
                    `/${course.courseId}/modulos`
                  )
                }
              >
                <div className="h-40">
                  <img
                    src={course.courseImage}
                    alt={course.courseName}
                    className="md:w-1/3 h-40 w-full rounded-t-xl object-cover md:rounded-l-lg md:rounded-t-none"
                  />
                </div>
                <div className="p-3 h-40 flex flex-col justify-between">
                  <h2 className="font-bold text-3xl text-center">
                    {course.courseName}
                  </h2>
                  <p className="text-lg text-center">
                    {course.courseDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))} */}

        {/* <Slider {...settings}>
          {userCourses.map((course: any, index: number) => (
            <div key={index} className="pb-5">
              <div className="flex justify-center ">
                <div
                  key={index}
                  className="bg-white w-5/6 h-80 rounded-lg shadow-lg md:flex"
                  onClick={() =>
                    navigation(
                      `/${course.courseId}/modulos`
                    )
                  }
                >
                  <img
                    src={course.courseImage}
                    alt={course.courseName}
                    className="md:w-1/3 rounded-t-lg md:rounded-l-lg md:rounded-t-none"
                  />
                  <div className="p-3 h-1/2 break-inside-auto break-normal">
                    <h2 className="h-1/3 font-bold text-xl md:text-3xl mb-2 text-orange-700">
                      {course.courseName}
                    </h2>
                    <p className="text-orange-700 h-3/5 line-clamp-3 pt-2">
                      {course.courseDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
        {/* //TODO: SECCION NOTICIAS a futuro */}
        {/* <div>
        <span className="flex justify-center font-black text-blue-500 text-xl mb-5">
          Sección Noticias
        </span>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
