import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface UserState {
  isAdmin: boolean;
}

const initialState: UserState = {
  isAdmin: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    actionIsAdmin: (state, action) => {
      return {
        ...state,
        isAdmin: action.payload,
      };
    },
  },
  extraReducers: (builder) => {},
});

export const { actionIsAdmin } = UserSlice.actions;

export default UserSlice.reducer;
