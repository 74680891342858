import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import { GenerateUid } from "../utils/GenerateUid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const actionCreateCourse = createAsyncThunk(
  "courses/createCourse",
  async (data: any) => {
    const MySwal = withReactContent(Swal);
    new Compressor(data.courseImage, {
      quality: 0.6,
      success(result: any) {
        const storage = getStorage();
        // Nombre con - en lugar de espacios
        const formattedName = data.courseName.replaceAll(" ", "-");
        const storageRef = ref(storage, `courses/${formattedName}`);
        const courseUid = GenerateUid();
        uploadBytes(storageRef, result).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (imageUrl) => {
            await setDoc(doc(db, `courses/${courseUid}`), {
              courseId: courseUid,
              courseImage: imageUrl,
              courseName: data.courseName,
              courseDescription: data.courseDescription,
            }).then(_ => {
              MySwal.fire({
                icon: "success",
                title: "El curso se creó con éxito",
                confirmButtonText: "Ok",
              });
            })
          });
        });
      },
      error(_) {
        MySwal.fire({
          icon: "error",
          title: "Verificar que este todo completo",
          confirmButtonText: "Ok",
        });
      },
    });
  }
);

export const actionGetAllCourses = createAsyncThunk(
  "courses/getAllCourses",
  async () => {
    const querySnapshot = await getDocs(collection(db, "courses"));

    let courses: any = [];

    querySnapshot.forEach((doc) => {
      courses.push(doc.data());
    });
    return courses;
  }
);

export const actionActivateCourse = createAsyncThunk(
  "courses/activateCourse",
  async ({
    userId,
    courseId,
    courseName,
    courseDescription,
    courseImage,
  }: {
    userId: string | undefined;
    courseId: string;
    courseName: string;
    courseDescription: string;
    courseImage: string;
  }) => {
    await setDoc(doc(db, `users/${userId}/courses/${courseId}`), {
      courseName: courseName,
      courseId: courseId,
      courseDescription: courseDescription,
      courseImage: courseImage,
    });
  }
);

export const actionDeactivateCourse = createAsyncThunk(
  "courses/deactivateCourse",
  async ({
    userId,
    courseId,
  }: {
    userId: string | undefined;
    courseId: string;
  }) => {
    await deleteDoc(doc(db, `users/${userId}/courses/${courseId}`));
  }
);
