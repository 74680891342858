import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { actionGetAllCourses } from "../../services/courses.services";
import { actionGetUserCourses } from "../../services/users.services";

const Courses = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const userData: any = localStorage.getItem("user");
  const userId: any = JSON.parse(userData).uid;

  const [userCourses, setUserCourses] = React.useState([]);
  const [allCourses, setAllCourses] = React.useState([]);

  React.useEffect(() => {
    Promise.all([
      dispatch(actionGetUserCourses(userId)),
      dispatch(actionGetAllCourses()),
    ]).then((coursePromiseResponse) => {
      setUserCourses(coursePromiseResponse[0].payload);

      /* Filtrar los cursos que ya tiene activos */
      let newCourses: any = coursePromiseResponse[1].payload;
      coursePromiseResponse[0].payload.map(
        (activeCourses: any) =>
          (newCourses = newCourses.filter(
            (elem: any) => elem.courseId !== activeCourses.courseId
          ))
      );

      setAllCourses(newCourses);
    });
  }, []);

  return (
    <>
      <div>
        {userCourses.length > 0 || allCourses.length > 0 ? (
          <div>
            {userCourses.map((elem: any, index) => (
              <div
                key={index}
                className="bg-orange-500 mb-5"
                onClick={() => navigation(`/${elem.courseId}/modulos`)}
              >
                {elem.courseName}
              </div>
            ))}
            {allCourses.map((elem: any, index) => (
              <div key={index}>{elem.courseName}</div>
            ))}
          </div>
        ) : (
          <div>No hay cursos</div>
        )}
      </div>
    </>
  );
};

export default Courses;
