import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import YoutubeEmbed from "../../../components/YoutubeVideo/YoutubeVideo";
import { getData, getDataActividades } from "../../../Firebase/Firestore";
import { useAppDispatch } from "../../../redux/hooks";
import {
	actionGetModule,
	actionGetModuleActivities,
} from "../../../services/modulos.services";
import { ModuleType } from "../../../types/ModuleTypes";

interface stateOneModule {
	moduleId: number;
	moduleName: string;
	moduleVideo: string;
	moduleImage: string;
}

const OneModule = () => {
	const params = useParams();
	const navigation = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	// const modulo = location.state as unknown as stateOneModule;

	const userData: any = localStorage.getItem("user");
	const userId: any = JSON.parse(userData).uid;

	const functionGetActividades = () => {
		navigation(`/${params.courseId}/${params.moduleId}/actividades`);
	};

	const [module, setModule] = React.useState<stateOneModule>();

	React.useEffect(() => {
		dispatch(
			actionGetModule({
				userId: userId,
				courseId: params.courseId,
				moduleId: params.moduleId,
			})
		).then((promiseModuleResponse: any) => {
			setModule(promiseModuleResponse.payload);
		});
	}, []);

	return (
		<>
			{module?.moduleId ? (
				<div className="pt-20 pb-10 md:flex md:flex-col md:justify-around md:h-screen">
					<span className="md:text-4xl flex justify-center font-black text-cyan-700 text-2xl mb-1">
						{module?.moduleName}
					</span>
					{/* <span className="md:text-2xl md:mt-5 flex justify-center font-black text-cyan-700 text-md mb-5">
						Actividades
					</span> */}
					<div className="md:flex md:justify-center md:items-center">
						<div className="md:w-3/4 md:flex md:justify-center md:items-center">
							<YoutubeEmbed
								embedId={module.moduleVideo}
							/>
						</div>
					</div>
					<div className="flex flex-col justify-center items-center mt-10">
						<button
							onClick={() =>
								functionGetActividades()
							}
							className="mb-5 bg-green-300 w-9/12 md:w-4/12 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						>
							<h1>Actividades</h1>
						</button>
					</div>
					<div className="flex flex-col justify-center items-center">
						<button
							onClick={() =>
								navigation(`/${params.courseId}/${params.moduleId}/preguntas`)
							}
							className="mb-5 bg-green-300 w-9/12 md:w-4/12 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						>
							<h1>Hacer una pregunta</h1>
						</button>
					</div>
				</div>
			) : (
				<div className="pt-40 flex justify-center items-center">
					<BounceLoader
						color={"#86EFAC"}
						loading={true}
						size={200}
					/>
				</div>
			)}
		</>
	);
};

export default OneModule;
