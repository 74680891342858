import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { SyncLoader } from "react-spinners";
import { useAppDispatch } from "../../../redux/hooks";
import { actionCreateCourse } from "../../../services/courses.services";

const CreateCourse = (props: any) => {
	const dispatch = useAppDispatch();

	type Inputs = {
		courseName: string;
		courseDescription: string;
	};

	const [imagen, setImagen] = React.useState();
	const [spinnerCreate, setSpinnerCreate] = React.useState(false);

	const changeImagen = (e: any) => {
		setImagen(e.target.files[0]);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		setSpinnerCreate(true);
		dispatch(
			actionCreateCourse({
				courseImage: imagen,
				courseName: data.courseName,
				courseDescription: data.courseDescription,
			})
		)
		setTimeout(() => {
			setSpinnerCreate(false)
		}, 3000)
	};

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);
	const handleClick = (event: any) => {
		hiddenFileInput.current!.click();
	};
	const handleChange = (e: any) => {
		setImagen(e.target.files[0]);
	};

	return (
		<div className="pt-20 pb-20">
			<span className="flex justify-center font-black text-cyan-700 text-4xl mt-5 mb-5">
				Crear un curso
			</span>
			<div className="pl-10 pt-10">
				<span className="flex justify-center font-black text-cyan-700 text-2xl mb-5">
					Seleccionar una imagen
				</span>
				<div className="h-52 flex flex-row items-center justify-center">
					{/* <input
						type="file"
						name="imagen"
						onChange={changeImagen}
						className="bg-green-200 h-52 flex flex-col"
					/> */}
					<button
						onClick={handleClick}
						className="w-32 h-32 flex justify-center items-center hover:text-green-500"
					>
						<div className="flex flex-col justify-around items-center">
							<FaUpload size={50} />
							<span className="mt-5">
								Subir una imagen
							</span>
						</div>
					</button>
					<input
						type="file"
						ref={hiddenFileInput}
						onChange={handleChange}
						style={{ display: "none" }}
					/>
					{imagen ? (
						<img
							className="w-52 h-52 border-2 ml-10 border-black rounded-sm"
							src={URL.createObjectURL(imagen)}
						/>
					) : (
						<div className="w-52 h-52 ml-10" />
					)}
				</div>

				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="flex flex-col mt-5 justify-center items-center">
						<input
							{...register("courseName", {
								required: true,
							})}
							className={
								errors?.courseName
									? "border-2 w-3/5 h-14 rounded-lg p-2 border-red-500 text-red-500 placeholder-red-500"
									: "border-blue-400 border-2 w-3/5 h-14 rounded-lg p-2"
							}
							placeholder="Nombre del Curso"
						/>
						{errors?.courseName?.type === "required" ? (
							<div className="h-5 mt-2 flex items-center ">
								<span>
									Este campo es requerido
								</span>
							</div>
						) : (
							<div className="h-5" />
						)}
						<input
							{...register("courseDescription", {
								required: true,
							})}
							className={
								errors?.courseDescription
									? "border-2 mt-10 w-3/5 h-14 rounded-lg p-2 border-red-500 text-red-500 placeholder-red-500"
									: "border-blue-400 border-2 mt-10 w-3/5 h-14 rounded-lg p-2"
							}
							placeholder="Descripción del Curso"
						/>
						{errors?.courseDescription?.type ===
						"required" ? (
							<div className="h-5 mt-2 flex items-center ">
								<span>
									Este campo es requerido
								</span>
							</div>
						) : (
							<div className="h-5" />
						)}
					</div>
					<div className="flex justify-center mt-10">
						{spinnerCreate ? (
							<SyncLoader
								color="green"
								loading={spinnerCreate}
							/>
						) : (
							<input
								className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl cursor-pointer"
								type="submit"
								value={"Crear curso"}
							/>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default CreateCourse;
