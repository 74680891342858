import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDuO553xI1LLM8rcpjpmhXhZm3MjmaHrx0",
  authDomain: "aulavirtualacab-ed44b.firebaseapp.com",
  projectId: "aulavirtualacab-ed44b",
  storageBucket: "aulavirtualacab-ed44b.appspot.com",
  messagingSenderId: "1052102150620",
  appId: "1:1052102150620:web:8c377308942a075f1b2719",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
