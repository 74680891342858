import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { actionGetAllModules } from "../../services/modulos.services";
import { actionGetUserModules } from "../../services/users.services";
import { ModuleType } from "../../types/ModuleTypes";
import BounceLoader from "react-spinners/BounceLoader";
import Slider from "react-slick";

const Modules = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const userData: any = localStorage.getItem("user");
  const userId: any = JSON.parse(userData).uid;

  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    rtl: false,
  };

  const [userModules, setUserModules] = React.useState<ModuleType[]>([]);
  const [allModules, setAllModules] = React.useState<ModuleType[]>([]);

  React.useEffect(() => {
    Promise.all([
      dispatch(
        actionGetUserModules({
          userId: userId,
          idCurso: params.courseId,
        })
      ),
      dispatch(actionGetAllModules(params.courseId)),
    ]).then((promiseModuleResponse: any) => {
      setUserModules(promiseModuleResponse[0].payload);

      /* Filtrar los modulos que ya tiene activos */
      let newModules: any = promiseModuleResponse[1].payload;
      promiseModuleResponse[0].payload.map((activeModules: any) => {
        return (newModules = newModules.filter(
          (newModules: any) => newModules.moduleId !== activeModules.moduleId
        ));
      });
      setAllModules(newModules);
    });
  }, [dispatch, userId, params.courseId]);

  return (
    <div className="flex-1 w-full">
      <div className="pt-20">
        <span className="flex justify-center font-black text-cyan-700 text-2xl mb-5">
          Modulos
        </span>
        {console.log("userModules", userModules)}

        {
          userModules.length === 0 ? (
            <div className="h-64 flex justify-center items-center">
              <BounceLoader color={"#86EFAC"} loading={true} size={200} />
            </div>
          ) : (
            // userModules.length === 1 ?
            <div className="flex flex-col justify-center md:flex-row">
              {userModules.map((module: any, index: number) => (
                <div key={index} className="pb-5">
                  <div className="flex justify-center ">
                    <div
                      key={index}
                      className="bg-white w-4/6 rounded-lg shadow-lg flex flex-col"
                      onClick={() =>
                        navigation(
                          `/${params.courseId}/${module.moduleId}/modulo`
                        )
                      }
                    >
                      <div className="">
                        <img
                          src={module.moduleImage}
                          alt={module.moduleName}
                          className="rounded-t-lg w-96 object-cover"
                        />
                      </div>
                      <div className="py-6 flex justify-center flex-col px-4">
                        <h2 className="font-bold text-lg text-center">
                          {module.moduleName}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
          // : (
          // 	<Slider {...settings}>
          // 		{userModules.map(
          // 			(module: any, index: number) => (
          // 				<div
          // 					key={index}
          // 					className="pb-5"
          // 				>
          // 					<div className="flex justify-center ">
          // 						<div
          // 							key={index}
          // 							className="bg-white w-4/6 h-60 rounded-lg shadow-lg flex flex-col"
          // 							onClick={() =>
          // 								navigation(
          // 									`/${params.courseId}/${module.moduleId}/modulo`
          // 								)
          // 							}
          // 						>
          // 							<div className="h-40">
          // 								<img
          // 									src={
          // 										module.moduleImage
          // 									}
          // 									alt={
          // 										module.moduleName
          // 									}
          // 									className="rounded-t-lg h-40 w-96 object-cover"
          // 								/>
          // 							</div>
          // 							<div className="h-20 flex justify-center flex-col px-4">
          // 								<h2 className="font-bold text-xl text-center">
          // 									{
          // 										module.moduleName
          // 									}
          // 								</h2>
          // 							</div>
          // 						</div>
          // 					</div>
          // 				</div>
          // 			)
          // 		)}
          // 	</Slider>
          // )
        }

        <div>
          <span className="flex justify-center font-black text-cyan-700 text-2xl mb-5">
            Proximos modulos
          </span>
          {
            allModules.length === 0 ? (
              <div className="h-64 flex justify-center items-center">
                <BounceLoader color={"#86EFAC"} loading={true} size={200} />
              </div>
            ) : (
              // allModules.length === 1 ?
              <div className="flex justify-center">
                {allModules.map((module: any, index: number) => (
                  <div key={index} className="pb-5">
                    <div className="flex justify-center">
                      <div
                        key={index}
                        className="bg-white w-4/6 h-60 rounded-lg shadow-lg flex flex-col blur-min"
                      >
                        <div className="h-40">
                          <img
                            src={module.moduleImage}
                            alt={module.moduleName}
                            className="rounded-t-lg h-40 w-96 object-cover"
                          />
                        </div>
                        <div className="h-20 flex justify-center flex-col px-4">
                          <h2 className="font-bold text-xl text-center">
                            {module.moduleName}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
            // : (
            // <Slider {...settings}>
            //   {allModules.map((module: any, index: number) => (
            //     <div key={index} className="pb-5">
            //       <div className="flex justify-center">
            //         <div
            //           key={index}
            //           className="w-5/6 h-60 rounded-lg shadow-lg md:flex blur-min"
            //         >
            //           <div className="h-40">
            //             <img
            //               src={module.moduleImage}
            //               alt={module.moduleName}
            //               className="rounded-t-lg h-40 object-cover"
            //             />
            //           </div>
            //           <div className="h-20 flex justify-center flex-col px-4">
            //             <h2 className="font-bold text-xl text-center">
            //               {module.moduleName}
            //             </h2>
            //           </div>
            //         </div>
            //       </div>
            //     </div>
            //   ))}
            // </Slider>

            // <Slider {...settings}>
            // 	{allModules.map(
            // 		(
            // 			module: any,
            // 			index: number
            // 		) => (
            // 			<div
            // 				key={index}
            // 				className="pb-5"
            // 			>
            // 				<div className="flex justify-center">
            // 					<div
            // 						key={
            // 							index
            // 						}
            // 						className="w-5/6 h-60 rounded-lg shadow-lg md:flex blur-min"
            // 					>
            // 						<div className="h-40">

            // 							<img
            // 								src={
            // 									module.moduleImage
            // 								}
            // 								alt={
            // 									module.moduleName
            // 								}
            // 								className="rounded-t-lg h-40 object-cover"
            // 							/>
            // 						</div>
            // 						<div className="h-20 flex justify-center flex-col px-4">
            // 							<h2 className="font-bold text-xl text-center">
            // 								{
            // 									module.moduleName
            // 								}
            // 							</h2>
            // 						</div>
            // 					</div>
            // 				</div>
            // 			</div>
            // 		)
            // 	)}
            // </Slider>
            // ''
            // 		)
          }
        </div>
      </div>
    </div>
  );
};

export default Modules;
