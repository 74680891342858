import React from "react";
import { useNavigate } from "react-router-dom";
import {
	forgotPassword,
	functionAuthWithEmail,
	IAuthEmail,
	test,
} from "../../Firebase/Auth";
import { HiOutlineMail, HiOutlineLockClosed } from "react-icons/hi";
import { userDataObject } from "../../model";
import {
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { useForm, SubmitHandler } from "react-hook-form";
import ImageBackground from "../../assets/images/loginBackgroundMobile.png";
import LogoACAB from "../../assets/images/logoAcab.png";
import { ADMIN_LIST } from "../../utils/ADMIN_LIST";

type Props = {
	text?: string;
};

const Login = ({ text }: Props) => {
	const navigate = useNavigate();

	/*
	 * Verificar si un usuario esta logueado o no,
	 * ============================
	 *  Agregar mas adelante una especie de loader hasta que termine de verificar si
	 *  el usuario esta logueado o no
	 * ============================
	 */

	React.useEffect(() => {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			localStorage.setItem("user", JSON.stringify(user));
			const isAdmin = ADMIN_LIST.includes(user?.uid!);
			if (isAdmin) {
				navigate("/admin/home");
			} else if (user) {
				navigate("/inicio");
			}
		});
	}, []);

	// ====================

	type Inputs = {
		userEmail: string;
		password: string;
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		functionAuthWithEmail({
			email: data.userEmail,
			password: data.password,
		});
	};

	return (
		<div className="h-screen bg-black bg-hero-pattern md:bg-hero-pattern-web bg-opacity-50 bg-blend-darken bg-cover bg-center flex flex-col justify-between">
			<div className="h-3/6 flex flex-col justify-center">
				<div className="flex justify-center items-center">
					<img src={LogoACAB} className="w-3/6 md:w-2/12" />
				</div>
				<div className="flex justify-center items-center">
					<span className="font-bold text-2xl md:text-3xl text-white">
						Aula virtual A.C.A.B.
					</span>
				</div>
			</div>
			<div className="h-3/6">
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="flex flex-col justify-center items-center">
						<div className="flex flex-col justify-evenly w-9/12 md:w-6/12">
							<div className="flex bg-gray-500 bg-opacity-40 rounded-md p-2 w-full shadow-lg shadow-black/50 ">
								<HiOutlineMail
									size={25}
									color={
										errors?.userEmail
											? "red"
											: "white"
									}
									className=""
								/>
								<input
									{...register(
										"userEmail",
										{
											required: true,
											pattern: /^\S+@\S+\.\S+$/,
										}
									)}
									className={
										"bg-transparent w-full placeholder-white outline-none text-white pl-3 placeholder-white/60"
									}
									placeholder="Email"
								/>
							</div>
							<div className="h-7 flex justify-center">
								{errors?.userEmail?.type ===
								"required" ? (
									<span className="text-white">
										Este campo es
										obligatorio
									</span>
								) : (
									errors?.userEmail
										?.type ===
										"pattern" && (
										<span className="text-white">
											Por favor
											revisar el
											email
										</span>
									)
								)}
							</div>
						</div>
						<div className="flex flex-col justify-evenly w-9/12 md:w-6/12">
							<div className="flex bg-gray-500 bg-opacity-40 rounded-md p-2 w-full shadow-lg shadow-black/50">
								<HiOutlineLockClosed
									size={25}
									color={
										errors?.password
											? "red"
											: "white"
									}
								/>
								<input
									{...register("password", {
										required: true,
									})}
									type="password"
									className={
										"w-full bg-transparent placeholder-white outline-none text-white pl-3 placeholder-white/60"
									}
									placeholder="Password"
								/>
							</div>
							<div className="h-7 flex justify-center">
								{errors?.password && (
									<span className="text-white">
										Este campo es
										obligatorio
									</span>
								)}
							</div>
						</div>

						<input
							className="bg-green-300 w-9/12 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl md:w-6/12"
							value="LOGIN"
							type="submit"
						/>
					</div>
				</form>
				<div className="flex justify-center mt-4">
					<span className="text-white md:text-lg md:mr-2">
						¿Olvidaste la contraseña?
					</span>
					<a
						className="text-green-300 md:text-lg "
						onClick={() => navigate("/forgot-password")}
					>
						¡Recuperala aquí!
					</a>
				</div>
			</div>
		</div>
	);
};

export default Login;
