import React from "react";
import { useParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { useAppDispatch } from "../../redux/hooks";
import { actionSaveActivities } from "../../services/activities.services";
import { actionGetModuleActivities } from "../../services/modulos.services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SyncLoader } from "react-spinners";
interface IActivity {
	answer: string | null;
	correction: boolean | null;
	edition: boolean;
	question: string;
	returns: string | null;
}

const Actividades = () => {
	const MySwal = withReactContent(Swal);
	const dispatch = useAppDispatch();
	const params = useParams();

	const userData: any = localStorage.getItem("user");
	const userId: any = JSON.parse(userData).uid;
	const [refreshActivities, setRefreshActivities] = React.useState(false);
	const [sendAnswers, setSendAnswers] = React.useState(false);

	React.useEffect(() => {
		dispatch(
			actionGetModuleActivities({
				userId: userId,
				courseId: params.courseId,
				moduleId: params.moduleId,
			})
		).then((responseGetModuleActivities) => {
			setActivities(
				responseGetModuleActivities.payload[0].activities
			);
			setActivityId(
				responseGetModuleActivities.payload[0].activityId
			);
			setActivityActive(
				responseGetModuleActivities.payload[0].sended
			);
		});
	}, [
		dispatch,
		params.courseId,
		params.moduleId,
		userId,
		refreshActivities,
	]);

	const [activityId, setActivityId] = React.useState("");
	const [activities, setActivities] = React.useState<IActivity[]>([]);
	const [activityActive, setActivityActive] =
		React.useState<boolean>(false);

	const onChangeResponse = (index: number, e: any) => {
		const newActivities = activities;

		newActivities[index].answer = e.target.value;

		return setActivities([...newActivities]);
	};

	const actionSendActivities = () => {
		setSendAnswers(true);
		for (let i = 0; i < activities.length; i++) {
			if (
				activities[i].answer === null ||
				activities[i].answer === ""
			) {
				MySwal.fire({
					icon: "error",
					title: "Para enviar tenes que contestar todas las preguntas",
					confirmButtonText: "Ok",
				});
				setSendAnswers(false);
				return;
			}
		}
		for (let i = 0; i < activities.length; i++) {
			activities[i].edition = false;
		}
		dispatch(
			actionSaveActivities({
				userId: userId,
				courseId: params.courseId,
				moduleId: params.moduleId,
				activityId: activityId,
				activityResponses: activities,
			})
		);
		setActivityActive(true);
		setSendAnswers(false);
		setRefreshActivities(!refreshActivities);

	};

	const inputContainerStyle = (correction: boolean | null) => {
		if (correction === null) {
			return "border-2 border-black";
		} else if (correction) {
			return "border-2 border-green-600";
		} else {
			return "border-2 border-red-600";
		}
	};

	return (
		<div className="pt-20 px-4 pb-10 md:flex md:flex-col md:items-center">
			<span className="flex justify-center font-black text-cyan-700 text-3xl mb-1">
				Actividades
			</span>
			{activities?.length > 0 ? (
				<div className="md:w-6/12 md:flex md:flex-col md:items-center">
					{activities?.map((activity, index) => (
						<div className="mt-5 md:w-full" key={index}>
							<span className="text-black text-left font-bold text-xl mb-2 underline">
								Pregunta {index + 1}:
							</span>
							<span className="text-black text-left font-medium text-xl flex justify-center mb-2">
								{activity.question}
							</span>
							<form action="#">
								<label className="relative">
									<span className="absolute bg-white rounded-xl mt-1 ml-1 pl-1 w-11/12 text-blue-500 font-medium">
										Respuesta:
									</span>
									<textarea
										disabled={
											activity.correction !=
											null
												? activity.correction! ==
												  true
													? true
													: activity.edition ==
													  true
													? false
													: true
												: activity.edition ==
												  false
												? true
												: false
										}
										// activity.correction!
										// !activity.edition
										className="pt-5 pl-1 pr-6 h-60 resize-none w-full outline-none border-blue-700 border-4 rounded-xl z-10"
										value={
											activity.answer ===
											null
												? ""
												: activity.answer
										}
										onChange={(e) =>
											onChangeResponse(
												index,
												e
											)
										}
										name={
											activity.question
										}
										key={index}
									/>
								</label>
							</form>
							{activity.returns && (
								<div>
									<span className="text-green-500 text-center font-medium text-lg flex justify-start mb-2">
										Devolución de la
										actividad
									</span>
									<textarea
										className={
											activity.correction
												? "pt-1 pl-1 pr-6 resize-none w-full h-36 outline-none border-green-700 border-4 rounded-xl z-10"
												: "pt-1 pl-1 pr-6 resize-none w-full h-36 outline-none border-red-500 border-4 rounded-xl z-10"
										}
										value={
											activity.returns
										}
										disabled
										name={
											activity.returns
										}
										key={index}
									/>
								</div>
							)}
						</div>
					))}
					<div className="flex items-center justify-center mt-5">
						<button
							disabled={activityActive}
							onClick={() => actionSendActivities()}
							className={`${
								!activityActive
									? "bg-green-300"
									: "bg-gray-300"
							}  w-9/12 md:w-full md:px-10 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl`}
						>
							{sendAnswers ? (
								<SyncLoader
									color="#fff"
									loading={sendAnswers}
								/>
							) : (
								<h1>Enviar</h1>
							)}
						</button>
					</div>
				</div>
			) : (
				<div className="pt-40 flex justify-center items-center">
					<BounceLoader
						color={"#86EFAC"}
						loading={true}
						size={200}
					/>
				</div>
			)}
		</div>
	);
};

export default Actividades;
