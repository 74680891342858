import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
	actionGetUserActivities,
	actionSaveActivityCorrection,
} from "../../../services/users.services";
import { FaCheck, FaTimes } from "react-icons/fa";
import BounceLoader from "react-spinners/BounceLoader";

interface userActivities {
	correction: null | boolean;
	returns: null | string;
	edition: boolean;
	question: string;
	answer: null | string;
}

const UserActivities = () => {
	const dispatch = useAppDispatch();
	const params = useParams();
	const navigation = useNavigate();

	const [userActivities, setUserActivities] = React.useState<
		userActivities[]
	>([]);

	const [activityId, setActivityId] = React.useState("");

	const [refreshState, setRefreshState] = React.useState("");

	const onTextChange = (e: any) => {
		setUserActivities(
			userActivities.map((elem) => {
				if (elem.question === e.target.name) {
					elem.returns = e.target.value;
				}
				return elem;
			})
		);
	};

	React.useEffect(() => {
		dispatch(
			actionGetUserActivities({
				userId: params.userId,
				idCurso: params.idCurso,
				moduleId: params.moduleId,
			})
		).then((activitiesResponse) => {
			setUserActivities(activitiesResponse.payload[0].activities);
			setActivityId(activitiesResponse.payload[0].activityId);
		});
	}, []);

	const handleFunctionCorrection = ({
		correction,
		index,
		edition,
	}: {
		correction: boolean;
		edition: boolean;
		index: number;
	}) => {
		const newUserActivities = userActivities;
		newUserActivities[index].correction = correction;
		newUserActivities[index].edition = edition;
		setUserActivities(newUserActivities);
		setRefreshState(Math.random().toString());
	};

	return (
		<div className="pt-20 pb-20">
			{userActivities.length > 0 ? (
				<div className="flex flex-col items-center">
					{userActivities.map((elem: any, index) => (
						<div
							key={index}
							className=" w-3/4 pl-10 pr-10 m-10 flex justify-around items-center border-black border-4 rounded-xl"
						>
							<div className=" p-5 w-3/5 rounded-xl">
								<div className="flex justify-center">
									<span className="flex justify-center font-black text-cyan-700 text-2xl mb-2">
										{elem.question}
									</span>
								</div>
								<h1 className="font-semibold mb-3">
									{elem.answer}
								</h1>
								<div className="flex justify-center ">
									<form
										action="#"
										className="w-full"
									>
										<label className="relative">
											<span className="absolute bg-white rounded-xl mt-1 ml-1 pl-1 w-11/12 text-blue-500 font-medium">
												Devolución:
											</span>
											<textarea
												className="pt-5 pl-1 pr-6 h-40 resize-none w-full outline-none border-blue-700 border-4 rounded-xl z-10"
												value={
													userActivities[
														index
													]
														.returns ||
													""
												}
												onChange={(
													e
												) =>
													onTextChange(
														e
													)
												}
												name={
													elem.question
												}
												key={
													index
												}
											/>
										</label>
									</form>
								</div>
							</div>
							<div className="flex flex-col h-60 justify-evenly ">
								<div className="">
									<button
										onClick={() =>
											handleFunctionCorrection(
												{
													correction:
														true,
													index,
													edition: false,
												}
											)
										}
										className={
											userActivities[
												index
											]
												.correction ===
											true
												? "text-green-500"
												: "text-black hover:text-green-500"
										}
									>
										<div className="flex items-center">
											<FaCheck
												size={
													40
												}
											/>
											<span className="ml-4">
												¡Respuesta
												correcta!
											</span>
										</div>
									</button>
								</div>
								<button
									onClick={() =>
										handleFunctionCorrection(
											{
												correction:
													false,
												index,
												edition: true,
											}
										)
									}
									className={`hover:text-red-500 
                  ${
				userActivities[index].correction == false
					? "text-red-500"
					: "text-black"
			}`}
								>
									<div className="flex items-center">
										<FaTimes
											size={40}
										/>
										<span className="ml-4">
											¡Respuesta
											incorrecta!
										</span>
									</div>
								</button>
							</div>
						</div>
					))}
					<div className="flex items-center justify-center mt-5">
						<button
							onClick={() =>
								dispatch(
									actionSaveActivityCorrection(
										{
											userId: params.userId,
											cursoId: params.idCurso,
											moduloId: params.moduleId,
											activityId:
												activityId,
											actividades:
												userActivities,
										}
									)
								)
							}
							className="bg-green-300 w-9/12 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						>
							<h1>ENVIAR CORRECCION</h1>
						</button>
					</div>
				</div>
			) : (
				<div className="pt-40 flex justify-center items-center">
					<BounceLoader
						color={"#86EFAC"}
						loading={true}
						size={200}
					/>
				</div>
			)}
		</div>
	);
};

export default UserActivities;
