import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { actionGetAllModules } from "../../../services/modulos.services";
import {
	actionGetNewMessages,
	actionSetNoNewMessagesInModule,
} from "../../../services/questions.services";

const QuestionModules = () => {
	const navigation = useNavigate();
	const dispatch = useAppDispatch();

	const [allModules, setAllModules] = React.useState([]);

	const [newMessages, setNewMessages] = React.useState<any>([]);

	React.useEffect(() => {
		dispatch(actionGetAllModules("1652964203156")).then(
			(allModules) => {
				setAllModules(allModules.payload);
			}
		);
		dispatch(actionGetNewMessages("1652964203156")).then((response) => {
			for (let i = 0; i < response.payload.length; i++) {
				if (response.payload[i].newMessages == true) {
					setNewMessages([
						...newMessages,
						parseInt(response.payload[i].moduleId),
					]);
				}
			}
		});
	}, []);

	return (
		<div className="h-screen w-screen pt-28">
			<span className="md:text-4xl flex justify-center font-black text-cyan-700 text-2xl mb-1">
				Lista de modulos
			</span>
			<div className="flex justify-center mt-10">
				{allModules.map((modulo: any, index) => (
					<div key={index} className="mx-10">
						<div className="flex justify-center relative">
							{newMessages.includes(
								modulo.moduleId
							) && (
								<div className="absolute top-10 w-full">
									<div className="bg-red-500 flex justify-center">
										<span className="text-white font-bold">
											NUEVOS
											MENSAJES
										</span>
									</div>
								</div>
							)}
							<div
								key={index}
								className="bg-white w-60 h-72 rounded-xl shadow-lg"
								onClick={() => {
									navigation(
										`/admin/question-modules/${modulo.moduleId}`
									);
									dispatch(
										actionSetNoNewMessagesInModule(
											{
												courseId: "1647607837012",
												moduleId: modulo.moduleId,
											}
										)
									);
								}}
							>
								<div className="h-40">
									<img
										src={
											modulo.moduleImage
										}
										alt={
											modulo.moduleName
										}
										className="h-40 w-full rounded-t-xl object-cover"
									/>
								</div>
								<div className="p-3 h-32 flex flex-col justify-between">
									<h2 className="font-bold text-3xl text-center">
										{modulo.moduleName}
									</h2>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default QuestionModules;
