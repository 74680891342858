import {
	createUserWithEmailAndPassword,
	getAuth,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { store } from "../redux/store";
import { actionIsAdmin } from "../redux/slices/user.slice";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "./config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { resolve } from "path";
import { ADMIN_LIST } from "../utils/ADMIN_LIST";

const firebaseConfig = {
	apiKey: "AIzaSyDuO553xI1LLM8rcpjpmhXhZm3MjmaHrx0",
	authDomain: "aulavirtualacab-ed44b.firebaseapp.com",
	projectId: "aulavirtualacab-ed44b",
	storageBucket: "aulavirtualacab-ed44b.appspot.com",
	messagingSenderId: "1052102150620",
	appId: "1:1052102150620:web:8c377308942a075f1b2719",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

const MySwal = withReactContent(Swal);

export interface IAuthEmail {
	email: string;
	password: string;
}

export const forgotPassword = ({ email }: { email: string }) => {
	sendPasswordResetEmail(auth, email)
		.then(function () {
			MySwal.fire({
				icon: "success",
				title: "Revisa tu email",
				confirmButtonText: "Ok",
			});
		})
		.catch(function (e) {
			MySwal.fire({
				icon: "error",
				title: "Email invalido",
				confirmButtonText: "Reintentar",
			});
		});
};

interface IUser {
	accessToken: string;
	emailVerified: boolean;
	uid: string;
	nombreCompleto: string;
	email: string;
}

export const functionAuthWithEmail = async ({
	email,
	password,
}: IAuthEmail) => {
	signInWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			let user = userCredential.user as unknown as IUser;

			const docRef = doc(db, `users/${user.uid}`);
			const docSnap = await getDoc(docRef);
			const dataUserFirestore = docSnap.data();

			user = {
				...user,
				nombreCompleto: dataUserFirestore!.nombreCompleto,
			};

			// VER LISTA DE UID DE USUARIOS ADMIN

			localStorage.setItem("user", JSON.stringify(user));
			const isAdmin = ADMIN_LIST.includes(user?.uid!);
			// if (user.uid == "2jV8T3E5mMVSSobZAtf82olLudQ2") {
			if (isAdmin) {
				localStorage.setItem(
					"isAdmin",
					JSON.stringify({ isAdmin: true })
				);
			} else {
				store.dispatch(actionIsAdmin(false));
			}

			return user;
		})
		.catch((error) => {
			MySwal.fire({
				icon: "error",
				title: "Email o contraseña invalidos",
				confirmButtonText: "Reintentar",
			});
			const errorCode = error.code;
			const errorMessage = error.message;
		});
};

export const test = () => {
	onAuthStateChanged(auth, (user) => {
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			// setUser(user);
			// ...
		} else {
			// User is signed out
			// ...
			// setUser(null)
		}
	});
};

export const functionCreateNewUser = ({
	email,
	password,
	nombreCompleto,
}: {
	email: string;
	password: string;
	nombreCompleto: string;
}) => {
	createUserWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			/* Crear documento en firestore con UID del user */
			await setDoc(doc(db, "users", userCredential.user.uid), {
				userId: userCredential.user.uid,
				email: userCredential.user.email,
				nombreCompleto: nombreCompleto,
			});
			MySwal.fire({
				icon: "success",
				title: "¡Usuario Creado!",
			});

			return userCredential;
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			MySwal.fire({
				icon: "error",
				title: "Error, intentelo nuevamente",
			});
			return errorMessage;
		});
};
