import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface IActivity {
	answer: string | null;
	correction: boolean | null;
	edition: boolean;
	question: string;
	returns: string | null;
}

export const actionSaveActivities = createAsyncThunk(
	"activities/saveActivities",
	async ({
		userId,
		courseId,
		moduleId,
		activityId,
		activityResponses,
	}: {
		userId: string | undefined;
		courseId: string | undefined;
		moduleId: string | undefined;
		activityId: string;
		activityResponses: IActivity[];
	}) => {
		const MySwal = withReactContent(Swal);
		// * Traer data del User y marcar que hay una actividad entregada
		await getDoc(doc(db, `users/${userId}`)).then(async (userData) => {
			const user = userData.data();
			await setDoc(doc(db, `/users/${userId}`), {
				...user,
				sended: true,
			});
		});

		await setDoc(
			doc(
				db,
				`/users/${userId}/courses/${courseId}/modules/${moduleId}/activities/${activityId}`
			),
			{
				activities: activityResponses,
				activityId,
				sended: true,
			}
		).then((_) => {
			MySwal.fire({
				icon: "success",
				title: "Tus respuestas fueron guardadas",
				confirmButtonText: "Ok",
			}).then((isConfirm) => {
				if (isConfirm) {
					window.location.reload();
				}
			});
		});
	}
);

export const actionGetResponses = createAsyncThunk(
	"activities/getResponses",
	async ({
		userId,
		moduleName,
	}: {
		userId: string | undefined;
		moduleName: string | undefined;
	}) => {
		const querySnapshot = await getDoc(
			doc(db, `users/${userId}/modulos/${moduleName}`)
		);
		return querySnapshot.data();
	}
);
