import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { functionCreateNewUser } from "../../../Firebase/Auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SyncLoader from "react-spinners/SyncLoader";

const CreateUsers = () => {
	const [buttonSpinner, setButtonSpinner] = useState<boolean>(false);

	const [dataInputRegister, setDataInputRegister] = useState({
		email: "",
		password: "",
		nombreCompleto: "",
	});

	const onChangeDataRegister = (e: any) => {
		setDataInputRegister({
			...dataInputRegister,
			[e.target.name]: e.target.value,
		});
	};

	const sendDataRegister = async () => {
		setButtonSpinner(true);
		functionCreateNewUser({
			email: dataInputRegister.email,
			password: dataInputRegister.password,
			nombreCompleto: dataInputRegister.nombreCompleto,
		});
		setTimeout(() => {
			setDataInputRegister({
				email: "",
				password: "",
				nombreCompleto: "",
			});
			setButtonSpinner(false);
		}, 3000);
	};

	return (
		<div className="pt-20 pb-20 flex flex-col w-full h-screen items-center">
			<span className="flex justify-center font-black text-cyan-700 text-4xl mb-20 mt-20">
				Crear un usuario
			</span>
			<input
				value={dataInputRegister.email}
				className="border-blue-400 border-2 w-1/2 h-14 rounded-lg p-2 mb-5"
				name="email"
				onChange={(e) => onChangeDataRegister(e)}
				placeholder="EMAIL"
			/>
			<input
				value={dataInputRegister.password}
				className="border-blue-400 border-2 w-1/2 h-14 rounded-lg p-2 mb-5"
				name="password"
				onChange={(e) => onChangeDataRegister(e)}
				placeholder="PASSWORD, MINIMO 6 CARACTERES"
			/>
			<input
				value={dataInputRegister.nombreCompleto}
				className="border-blue-400 border-2 w-1/2 h-14 rounded-lg p-2 mb-10"
				name="nombreCompleto"
				onChange={(e) => onChangeDataRegister(e)}
				placeholder="NOMBRE COMPLETO"
			/>
			<button
				className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
				onClick={() => sendDataRegister()}
			>
				{buttonSpinner ? (
					<SyncLoader
						color="#fff"
						loading={buttonSpinner}
					/>
				) : (
					"Crear usuario"
				)}
				{/* Crear Usuario
			<SyncLoader color={"red"} loading={true} /> */}
			</button>
		</div>
	);
};

export default CreateUsers;
