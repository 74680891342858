import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const actionGetAllUsers = createAsyncThunk(
	"users/getAllUsers",
	async () => {
		const querySnapshot = await getDocs(collection(db, "users"));

		let userList: any = [];

		querySnapshot.forEach((doc) => {
			userList.push(doc.data());
		});

		const usersSortedBySended = userList.sort((a: any, _:any) => {
			if (a.sended) {
				return -1;
			} else {
				return 1;
			}
		});

		return usersSortedBySended;
	}
);

export const actionGetUserCourses = createAsyncThunk(
	"users/getUserCourses",
	async (userId: string | undefined) => {
		const querySnapshot = await getDocs(
			collection(db, `users/${userId}/courses`)
		);

		let userCourses: any = [];

		querySnapshot.forEach((doc) => {
			userCourses.push(doc.data());
		});
		return userCourses;
	}
);

export const actionGetUserModules = createAsyncThunk(
	"users/getUserModules",
	async ({
		userId,
		idCurso,
	}: {
		userId: string | undefined;
		idCurso: string | undefined;
	}) => {
		const querySnapshot = await getDocs(
			collection(db, `users/${userId}/courses/${idCurso}/modules`)
		);

		let userModules: any = [];

		querySnapshot.forEach((doc) => {
			userModules.push(doc.data());
		});
		return userModules;
	}
);

export const actionGetUserActivities = createAsyncThunk(
	"users/getUserActivities",
	async ({
		userId,
		idCurso,
		moduleId,
	}: {
		userId: string | undefined;
		idCurso: string | undefined;
		moduleId: string | undefined;
	}) => {
		const querySnapshot = await getDocs(
			collection(
				db,
				`users/${userId}/courses/${idCurso}/modules/${moduleId}/activities`
			)
		);

		let userActivities: any = [];

		querySnapshot.forEach((doc) => {
			userActivities.push(doc.data());
		});
		return userActivities;
	}
);

export const actionSaveActivityCorrection = createAsyncThunk(
	"users/saveActivityCorrection",
	async ({
		userId,
		cursoId,
		moduloId,
		activityId,
		actividades,
	}: {
		userId: string | undefined;
		cursoId: string | undefined;
		moduloId: string | undefined;
		activityId: string;
		actividades: any;
	}) => {
		const MySwal = withReactContent(Swal);
		// * Traer data del User y marcar que ya NO hay actividades entregadas...
		await getDoc(doc(db, `users/${userId}`)).then(async (userData) => {
			const user = userData.data();
			await setDoc(doc(db, `/users/${userId}`), {
				...user,
				sended: false,
			});
		});

		await setDoc(
			doc(
				db,
				`users/${userId}/courses/${cursoId}/modules/${moduloId}/activities/${activityId}`
			),
			{
				activities: actividades,
				activityId: activityId,
				sended: false,
			}
		).then((success) => {
			MySwal.fire({
				icon: "success",
				title: "Se envió la corrección",
				confirmButtonText: "Ok",
			});
		});
	}
);

// export const actionCreateModule = createAsyncThunk(
//   "modules/createModule",
//   async (data: dataCreateModule) => {
//     new Compressor(data.imagen, {
//       quality: 0.6,
//       success(result: any) {
//         const storage = getStorage();
//         // Nombre con - en lugar de espacios
//         const formattedName = data.name.replaceAll(" ", "-");
//         const formattedNameWOSpaces = data.name.replaceAll(" ", "");
//         const storageRef = ref(storage, formattedName);
//         uploadBytes(storageRef, result).then((snapshot) => {
//           getDownloadURL(snapshot.ref).then(async (imageUrl) => {
//             await setDoc(doc(db, "modulos", `${formattedNameWOSpaces}`), {
//               id: formattedNameWOSpaces,
//               imagen: imageUrl,
//               nombre: data.name,
//               video: data.video,
//             }).then(async (uploadSuccess) => {
//               await setDoc(
//                 doc(
//                   db,
//                   "modulos",
//                   `${formattedNameWOSpaces}`,
//                   "actividades",
//                   "preguntas"
//                 ),
//                 {
//                   listaPreguntas: data.questions,
//                 }
//               );
//             });
//           });
//         });
//       },
//       error(err) {
//       },
//     });
//   }
// );
