import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";

export const PrivateRoutes = ({ children }: { children?: any }) => {
  const userData: any = localStorage.getItem("user");
  const userId: any = JSON.parse(userData)?.uid;

  const isAuthenticated = userId;
  const location = useLocation();

  const userIsAdmin = useSelector( (state: RootState) => state.userReducer.isAdmin);


  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};
