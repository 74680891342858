import React from "react";
import { forgotPassword } from "../../Firebase/Auth";
import { HiOutlineMail } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

const ForgorPassword = () => {
	const navigate = useNavigate();

	type Inputs = {
		userForgotEmail: string;
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		forgotPassword({
			email: data.userForgotEmail,
		});
	};

	return (
		<div className="h-screen bg-black bg-hero-pattern bg-opacity-50 bg-blend-darken bg-cover bg-center flex flex-col justify-center">
			<div className="flex justify-center items-center pl-10 pr-10">
				<span className="font-bold text-2xl text-white text-center">
					Ingresa el email con el que estás registrado
				</span>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="flex flex-col mt-10 mb-5 justify-center items-center">
					<div className="flex flex-col justify-evenly w-9/12 md:w-6/12">
						<div className="flex bg-gray-500 bg-opacity-40 rounded-md p-2 w-full shadow-lg shadow-black/50">
							<HiOutlineMail
								size={25}
								color={
									errors?.userForgotEmail
										? "red"
										: "white"
								}
							/>
							<input
								// autoComplete={"off"}
								{...register(
									"userForgotEmail",
									{
										required: true,
										pattern: /^\S+@\S+\.\S+$/,
									}
								)}
								className={
									"w-full bg-transparent placeholder-white outline-none text-white pl-3 placeholder-white/60"
								}
								placeholder="Email"
								name="userForgotEmail"
							/>
						</div>
					</div>
					<div className="h-7 flex justify-center">
						{/* {errors?.userEmail?.type === "required" ? (
              <span>This field is required</span>
            ) : (
              errors?.userEmail?.type === "pattern" && (
                <span>Por favor revisar el email</span>
              )
            )} */}
					</div>
					<input
						className="bg-green-300 w-9/12 p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl md:w-6/12"
						type="submit"
						value="Enviar"
					/>
				</div>
			</form>
			<div className="flex justify-center">
				<a
					className="text-green-300 text-lg md:text-xl"
					onClick={() => {
						navigate("/");
					}}
				>
					Ir a loguearse
				</a>
			</div>
		</div>
	);
};

export default ForgorPassword;
