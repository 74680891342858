import React from "react";
import { useAppDispatch } from "../../redux/hooks";
import { actionGetAllDocumentsByCourse } from "../../services/documents.services";

const CourseDocuments = () => {
	const dispatch = useAppDispatch();

	const [allDocuments, setAllDocuments] = React.useState([]);

	React.useEffect(() => {
		dispatch(actionGetAllDocumentsByCourse()).then((allDoc) => {
			setAllDocuments(allDoc.payload);
		});
	}, []);

	const n = 3;

	const result = new Array(Math.ceil(allDocuments.length / n))
		.fill(0)
		.map((_) => allDocuments.splice(0, n));

	return (
		<div className="pt-20">
			<h1 className="flex justify-center font-black text-cyan-700 text-4xl my-5 text-center mb-14">
				Documentos
			</h1>
			<div className="md:flex md:flex-col">
				{result.map((Arrdoc: any, index) => (
					<div className="md:flex md:flex-row md:justify-center">
						{Arrdoc.map(
							(doc: any, docIndex: number) => (
								<div
									key={docIndex + index}
									className="pb-5"
								>
									<div className="flex justify-center">
										<a
											href={
												doc.documentUrl
											}
											key={index}
											className="bg-white p-2 w-4/6 h-60 rounded-lg shadow-lg flex flex-col"
										>
											<div className="h-40 flex justify-center">
												<img
													src={
														"https://i0.wp.com/www.fundacionttm.org/wp-content/uploads/2016/11/Documento-R.png?fit=810%2C810&ssl=1"
													}
													alt={
														"imagen de documento"
													}
													className="rounded-t-lg h-40 w-96 object-contain"
												/>
											</div>
											<div className="h-20 flex justify-center flex-col px-4">
												<h2 className="font-bold text-xl text-center">
													{
														doc.name
													}
												</h2>
											</div>
										</a>
									</div>
								</div>
							)
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CourseDocuments;
