import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import { GenerateUid } from "../utils/GenerateUid";

export const actionCreateMessageQuestion = createAsyncThunk(
	"questions/createMessageQuestion",
	async (data: any) => {
		const questionId = GenerateUid();
		setDoc(
			doc(
				db,
				`/questions/${data.courseId}/courses/${data.courseId}/modules/${data.moduleId}/questions/${questionId}`
			),
			{
				messageId: questionId,
				message: data.message,
				nameMessage: data.nameMessage,
				admin: data.admin,
			}
		);
	}
);

export const actionSetNewMessagesInModule = createAsyncThunk(
	"questions/setNewMessagesInModule",
	async (data: any) => {
		setDoc(
			doc(
				db,
				`/questionsNews/${data.courseId}/courses/${data.courseId}/modules/${data.moduleId}`
			),
			{
				moduleId: data.moduleId,
				newMessages: true,
			}
		);
	}
);

export const actionSetNoNewMessagesInModule = createAsyncThunk(
	"questions/setNewMessagesInModule",
	async (data: any) => {
		setDoc(
			doc(
				db,
				`/questionsNews/${data.courseId}/courses/${data.courseId}/modules/${data.moduleId}`
			),
			{
				moduleId: data.moduleId,
				newMessages: false,
			}
		);
	}
);

export const actionGetNewMessages = createAsyncThunk(
	"questions/getAllMessagesByModule",
	async (data: any) => {
		const querySnapshot = await getDocs(
			collection(
				db,
				`/questionsNews/${data}/courses/${data}/modules`
			)
		);

		let messages: any = [];

		querySnapshot.forEach((doc) => {
			messages.push(doc.data());
		});
		
		return messages;
	}
);

export const actionGetAllMessagesByModule = createAsyncThunk(
	"questions/getAllMessagesByModule",
	async (data: any) => {
		const querySnapshot = await getDocs(
			collection(
				db,
				`/questions/${data.courseId}/courses/${data.courseId}/modules/${data.moduleId}/questions/`
			)
		);

		let messages: any = [];

		querySnapshot.forEach((doc) => {
			messages.push(doc.data());
		});

		return messages;
	}
);

export const actionActivateCourse = createAsyncThunk(
	"courses/activateCourse",
	async ({
		userId,
		courseId,
		courseName,
		courseDescription,
		courseImage,
	}: {
		userId: string | undefined;
		courseId: string;
		courseName: string;
		courseDescription: string;
		courseImage: string;
	}) => {
		await setDoc(doc(db, `users/${userId}/courses/${courseId}`), {
			courseName: courseName,
			courseId: courseId,
			courseDescription: courseDescription,
			courseImage: courseImage,
		});
	}
);

export const actionDeactivateCourse = createAsyncThunk(
	"courses/deactivateCourse",
	async ({
		userId,
		courseId,
	}: {
		userId: string | undefined;
		courseId: string;
	}) => {
		await deleteDoc(doc(db, `users/${userId}/courses/${courseId}`));
	}
);
