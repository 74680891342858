import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import { GenerateUid } from "../utils/GenerateUid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const actionGetAllModules = createAsyncThunk(
  "modules/getAllModules",
  async (courseId: string | undefined) => {
    const querySnapshot = await getDocs(
      collection(db, `courses/${courseId}/modules`)
    );

    let modulos: any = [];

    querySnapshot.forEach((doc) => {
      modulos.push(doc.data());
    });
    return modulos;
  }
);

export const actionGetAllModulesByCourse = createAsyncThunk(
  "modules/getAllModulesByCourse",
  async (courseId: string | undefined) => {
    const querySnapshot = await getDocs(
      collection(db, `courses/${courseId}/modules`)
    );

    let modules: any = [];

    querySnapshot.forEach((doc) => {
      modules.push(doc.data());
    });

    return modules;
  }
);

export const actionGetModule = createAsyncThunk(
  "modules/getModule",
  async ({
    userId,
    courseId,
    moduleId,
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string | undefined;
  }) => {
    const docRef = await getDoc(
      doc(db, `users/${userId}/courses/${courseId}/modules/${moduleId}`)
    );
    
    return docRef.data();
  }
);

export const actionGetModuleActivities = createAsyncThunk(
  "modules/getModuleActivities",
  async ({
    userId,
    courseId,
    moduleId,
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string | undefined;
  }) => {
    const moduleActivities: any = [];
    const querySnapshot = await getDocs(
      collection(
        db,
        `/users/${userId}/courses/${courseId}/modules/${moduleId}/activities`
      )
    );
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        moduleActivities.push(doc.data());
      });
      return moduleActivities;
    } else {
      return [];
    }
  }
);

interface dataCreateModule {
  moduleImage: any;
  moduleName: string;
  moduleVideo: string;
  moduleQuestions: string[];
  courseId: string | undefined;
}

export const actionCreateModule = createAsyncThunk(
  "modules/createModule",
  async (data: dataCreateModule) => {
    const MySwal = withReactContent(Swal);
    new Compressor(data.moduleImage, {
      quality: 0.6,
      success(result: any) {
        const storage = getStorage();
        // Nombre con - en lugar de espacios
        const formattedName = data.moduleName.replaceAll(" ", "-");
        const storageRef = ref(storage, `modules/${formattedName}`);
        const moduleUid = GenerateUid();
        uploadBytes(storageRef, result).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (imageUrl) => {
            await setDoc(
              doc(db, `courses/${data.courseId}/modules/${moduleUid}`),
              {
                moduleId: moduleUid,
                moduleImage: imageUrl,
                moduleName: data.moduleName,
                moduleVideo: data.moduleVideo,
              }
            ).then(async (uploadSuccess) => {
              const questionsUid = GenerateUid();
              await setDoc(
                doc(
                  db,
                  `courses/${data.courseId}/modules/${moduleUid}/activities/${questionsUid}`
                ),
                {
                  questionList: data.moduleQuestions,
                  activityId: questionsUid,
                }
              );
            }).then(_ => {
              MySwal.fire({
                icon: "success",
                title: "El módulo se creó con éxito",
                confirmButtonText: "Ok",
              });
            })
          });
        });
      },
      error(_) {
        MySwal.fire({
          icon: "error",
          title: "Verificar que este todo completo",
          confirmButtonText: "Ok",
        });
      },
    });
  }
);

// =======================

export const actionActivateModule = createAsyncThunk(
  "modules/activateModule",
  async ({
    userId,
    courseId,
    moduleId,
    moduleName,
    moduleImage,
    moduleVideo
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string;
    moduleName: string;
    moduleImage: string;
    moduleVideo: string;
  }) => {
    await setDoc(
      doc(db, `users/${userId}/courses/${courseId}/modules/${moduleId}`),
      {
        moduleId: moduleId,
        moduleName: moduleName,
        moduleImage: moduleImage,
        moduleVideo: moduleVideo,
      }
    );

    // Traer las actividades del modulo y darle formato para subirlos a la db del usuario
    const querySnapshot = await getDocs(
      collection(db, `courses/${courseId}/modules/${moduleId}/activities`)
    );

    let activities: any = [];
    let activityId: string = "";

    querySnapshot.forEach((doc) => {
      activityId = doc.data().activityId;
      doc.data().questionList.map((question: any) => {
        return activities.push({
          correction: null,
          returns: null,
          edition: true,
          question: question,
          answer: null,
        });
      });
    });

    // Subir las actividades al usuario
    await setDoc(
      doc(
        db,
        `users/${userId}/courses/${courseId}/modules/${moduleId}/activities/${activityId}`
      ),
      {
        activityId: activityId,
        activities: activities,
        sended: false
      }
    );
  }
);

export const actionDeactivateModule = createAsyncThunk(
  "modules/deactivateModule",
  async ({
    userId,
    courseId,
    moduleId,
  }: {
    userId: string | undefined;
    courseId: string | undefined;
    moduleId: string;
  }) => {
    const querySnapshot = await getDocs(
      collection(
        db,
        `users/${userId}/courses/${courseId}/modules/${moduleId}/activities`
      )
    );

    let activityId: string = "";

    querySnapshot.forEach((doc) => {
      activityId = doc.data().activityId;
    });

    await deleteDoc(
      doc(
        db,
        `users/${userId}/courses/${courseId}/modules/${moduleId}/activities/${activityId}`
      )
    );
    await deleteDoc(
      doc(db, `users/${userId}/courses/${courseId}/modules/${moduleId}`)
    );
  }
);
