import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { useAppDispatch } from "../../redux/hooks";
import { actionGetUserCourses } from "../../services/users.services";

interface userCourse {
	courseDescription: string;
	courseId: number;
	courseImage: string;
	courseName: string;
}

const CoursePresentation = () => {
	const userData: any = localStorage.getItem("user");
	const userId: any = JSON.parse(userData).uid;

	const dispatch = useAppDispatch();
	const params = useParams();
	const navigation = useNavigate();

	const [userCourse, setUserCourse] = React.useState<userCourse>();

	React.useEffect(() => {
		Promise.all([dispatch(actionGetUserCourses(userId))]).then(
			(coursePromiseResponse) => {
				const actualCourse =
					coursePromiseResponse[0].payload.filter(
						(course: any) =>
							course.courseId == params.courseId
					);
				setUserCourse(actualCourse[0]);
			}
		);
	}, []);

	return (
		<>
			<div className="pt-20">
				{userCourse?.courseName ? (
					<div className="flex flex-col items-center pb-10">
						<img
							src={userCourse.courseImage}
							alt={userCourse.courseName}
							className="h-60 w-3/4 object-cover rounded-lg shadow-lg shadow-black/50 md:w-60"
						/>
						<h1 className="flex justify-center font-black text-cyan-700 text-4xl my-5 text-center">
							{userCourse.courseName}
						</h1>
						<h2 className="flex justify-center px-7 text-black font-bold text-lg text-center md:px-24">
							¡BIENVENIDO/A!
						</h2>
						<h2 className="flex justify-center px-7 text-black text-lg text-center md:px-24">
							Este curso tiene como objetivo
							introducirte a la vida arbitral,
							formar nuevos árbitros y árbitras que
							contribuyan en el crecimiento del
							handball en Córdoba, la FCH y la ACAB.
							Al completar el curso, podrás arbitrar
							encuentros y partidos de la categoría
							MINI e INFANTIL. Está pensado para ser
							completado en un corto tiempo, y
							rápidamente puedas comenzar a
							vivenciar las prácticas, para que en
							unos pocos meses puedas realizar un
							curso de profundización y
							especificidad, y así dirigir partidos
							de categorías MENOR y CADETE de la
							FCH. El presente curso está compuesto
							por 5 módulos (4 puramente teóricos, y
							el último de evaluación y práctica), y
							en cada uno de esos módulos habrá una
							clase grabada (que podrás ver en el
							tiempo que dispongas), una actividad
							obligatoria, y un foro de preguntas,
							para que puedas dejar cualquier
							comentario o duda que tengas respecto
							a esa clase, que será respondida por
							cualquiera de los/as responsables del
							curso. Ahora bien, previo al inicio
							del curso habrá una clase presencial
							para explicar todo en detalle, y una
							vez finalizado el recorrido virtual,
							una clase presencial de cierre a esa
							etapa, para dar comienzo a las
							prácticas. Es MUY importante, que
							dispongas de los sábados por la mañana
							libre para poder asistir a estos
							encuentros, para crecer, aprender y
							llevar a la práctica todo lo
							aprendido. En cuanto al pago del
							curso, recordá que puede ser un pago
							de $5000, o dos pagos de $3000. De una
							u otra manera, el curso debe estar
							abonado en su totalidad para poder
							comenzar las prácticas de arbitraje. Y
							por último, el grupo de Whatsapp será
							el canal para mantenernos en contacto,
							avisar cualquier novedad, pero también
							para que puedas preguntar todo lo que
							necesites. ¡ÉXITOS EN ESTE NUEVO
							CAMINO! AQUÍ ESTAREMOS PARA
							ACOMPAÑARTE. ACAB
						</h2>
						<div className="flex items-center justify-center mt-5">
							<button
								onClick={() =>
									navigation(
										`/${params.courseId}/modulos`
									)
								}
								className="bg-green-300 py-4 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
								// className="mt-28 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
							>
								<h1>Comenzar</h1>
							</button>
						</div>
					</div>
				) : (
					<div className="mt-20 flex justify-center items-center">
						<BounceLoader
							color={"#86EFAC"}
							loading={true}
							size={200}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default CoursePresentation;
