import React from "react";
import { FaArrowRight, FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { actionGetAllUsers } from "../../../services/users.services";
import ReactPaginate from "react-paginate";
import { ADMIN_LIST } from "../../../utils/ADMIN_LIST";

interface Users {
	email: string;
	nombreCompleto: string;
	userId: string;
	sended?: boolean;
}

const UsersList = () => {
	const dispatch = useAppDispatch();
	const navigation = useNavigate();
	const itemsPerPage = 10;

	const [currentItems, setCurrentItems] = React.useState<Users[]>([]);
	const [pageCount, setPageCount] = React.useState(0);
	const [itemOffset, setItemOffset] = React.useState(0);

	const handlePageClick = (event: any) => {
		const newOffset =
			(event.selected * itemsPerPage) % allUserList.length;
		setItemOffset(newOffset);
		setCurrentItems(
			allUserList.slice(
				(event.selected * itemsPerPage) % allUserList.length,
				((event.selected * itemsPerPage) % allUserList.length) +
					itemsPerPage
			)
		);
	};

	React.useEffect(() => {
		dispatch(actionGetAllUsers());
	}, []);

	const allUserList = useAppSelector(
		(state) => state.allUserReducer.usersList
	);

	React.useEffect(() => {
		const endOffset = itemOffset + itemsPerPage;
		setCurrentItems(allUserList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(allUserList.length / itemsPerPage));
	}, [allUserList]);

	const actionTestGetUser = (userId: string) => {
		navigation(`/admin/users-activities/${userId}/cursos`);
	};

	const [searchValue, setSearchValue] = React.useState("");

	React.useEffect(() => {
		const newAllUserList = allUserList.filter((user) =>
			user.nombreCompleto
				.toLowerCase()
				.includes(searchValue.toLowerCase())
		);
		const endOffset = itemOffset + itemsPerPage;
		setCurrentItems(newAllUserList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(newAllUserList.length / itemsPerPage));
	}, [searchValue]);

	return (
		<div className="pt-20 pb-20">
			<h1 className="flex justify-center font-black text-cyan-700 text-4xl my-5 text-center">
				Lista de usuarios
			</h1>
			<div className="flex justify-center">
				<input
					placeholder="Buscar usuario por nombre"
					className="border-blue-400 border-2 w-3/5 h-14 rounded-lg p-2"
					type="text"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</div>
			<div className="">
				{currentItems.length > 0 &&
					currentItems.map((user, index) => (
						<div key={index} className="">
							<a
								onClick={() =>
									actionTestGetUser(
										user.userId
									)
								}
								key={user.userId}
								className={`flex items-center h-16 shadow-xl m-12 p-5 rounded-lg
									${ADMIN_LIST.includes(user.userId) ? "bg-green-200" : user.sended ? "bg-orange-400" : "bg-gray-50"}
								`}
							>
								<p className="w-3/6 pl-5 flex justify-start items-center">
									{user.nombreCompleto}
									{ADMIN_LIST.includes(
										user.userId
									) && <span className="ml-1">ADMINISTRADOR</span>}
								</p>
								<p className="w-2/6 flex justify-start items-center">
									{user.email}
								</p>
								<div className="w-1/6 flex justify-center items-center">
									<FaArrowRight size={50} />
								</div>
							</a>
						</div>
					))}
			</div>
			<div className="flex flex-row justify-center">
				<ReactPaginate
					className="flex flex-row w-1/2 justify-evenly text-2xl mt-20"
					breakLabel="..."
					nextLabel="→"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel="←"
					activeClassName="bg-cyan-200 text-cyan-900 rounded-2xl px-2"
					// renderOnZeroPageCount={null}
				/>
			</div>
		</div>
	);
};

const test = () => {
	return <div>Hola</div>;
};

export default UsersList;
