import React, { useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
	actionCreateMessageQuestion,
	actionGetAllMessagesByModule,
	actionSetNewMessagesInModule,
} from "../../../services/questions.services";

interface messages {
	admin: boolean;
	message: string;
	messageId: number;
	nameMessage: string;
}

const ModuleQuestions = () => {
	const [value, setValue] = useState("");
	const [rows, setRows] = useState(1);
	const minRows = 1;
	const maxRows = 5;
	const params = useParams();

	const userData: any = localStorage.getItem("user");
	const userId: any = JSON.parse(userData);

	const [allMessages, setAllMessages] = useState<messages[]>([]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			actionGetAllMessagesByModule({
				courseId: params.courseId,
				moduleId: params.moduleId,
			})
		).then((messagesResponses) => {
			setAllMessages(messagesResponses.payload);
		});
	}, []);

	const handleChange = (event: any) => {
		const textareaLineHeight = 26;

		const previousRows = event.target.rows;
		event.target.rows = minRows;

		const currentRows = ~~(
			event.target.scrollHeight / textareaLineHeight
		);

		if (currentRows === previousRows) {
			event.target.rows = currentRows;
		}

		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}
		setValue(event.target.value);
		setRows(currentRows < maxRows ? currentRows : maxRows);
	};

	const actionAddQuestionAsync = ({
		value,
		nameMessage,
	}: {
		value: string;
		nameMessage: string;
	}) => {
		setAllMessages([
			...allMessages,
			{
				admin: false,
				message: value,
				messageId: 0,
				nameMessage: nameMessage,
			},
		]);
		// * Clear the input
		setValue("");
	};

	return (
		<div className="relative ">
			<div className="bg-gray-50 pt-14 h-screen w-full  px-2 overflow-scroll pb-16">
				{allMessages &&
					allMessages.map((message) => (
						<div
							className={`flex mt-5 ${
								message.admin
									? "justify-end"
									: "justify-start"
							}`}
						>
							<div className="w-3/4 h-auto">
								<div className="bg-white p-3 rounded-md border-gray-300 border-2 ">
									<span>
										{message.message}
									</span>
								</div>
								<div className="flex justify-end mt-1">
									<span>
										{
											message.nameMessage
										}
									</span>
								</div>
							</div>
						</div>
					))}
			</div>
			<div className="absolute bottom-0">
				<div className="bg-green-300 w-screen h-auto py-3 flex items-center pl-2 pr-5 justify-between">
					<textarea
						rows={rows}
						value={value}
						placeholder={"Ingresa tu pregunta..."}
						className={
							"bg-green-20 border-green-400 border-2 rounded-2xl p-2 px-4 w-3/4 resize-none placeholder:text-gray-500"
						}
						onChange={handleChange}
					/>
					<button
						className="bg-blue-500 rounded-full p-2"
						onClick={() => {
							dispatch(
								actionCreateMessageQuestion({
									courseId: params.courseId,
									moduleId: params.moduleId,
									message: value,
									nameMessage:
										userId.nombreCompleto,
									admin: false,
								})
							);
							dispatch(
								actionSetNewMessagesInModule({
									courseId: params.courseId,
									moduleId: params.moduleId,
								})
							);
							actionAddQuestionAsync({
								value: value,
								nameMessage:
									userId.nombreCompleto,
							});
						}}
					>
						<AiOutlineSend size={25} color="#fff" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModuleQuestions;
