import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import ForgorPassword from "../screens/Auth/ForgotPassword";
import Login from "../screens/Auth/Login";
import { AdminRoutes } from "./AdminRoutes";
import { PrivateAdminRoutes } from "./PrivateAdminRoutes";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { UserRoutes } from "./UserRoutes";

const MainRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoutes>
            <ForgorPassword />
          </PublicRoutes>
        }
      />
      <Route
        path="/admin/*"
        element={
          <PrivateAdminRoutes>
            <AdminRoutes />
          </PrivateAdminRoutes>
        }
      />
      <Route
        path="/*"
        element={
          <PrivateRoutes>
            <UserRoutes />
          </PrivateRoutes>
        }
      />
    </Routes>
  );
};

export default MainRouter;
