import { createAsyncThunk } from "@reduxjs/toolkit";
import Compressor from "compressorjs";
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	setDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../Firebase/config";
import { GenerateUid } from "../utils/GenerateUid";

export const actionGetAllDocumentsByCourse = createAsyncThunk(
	"documents/getAllDocumentsByCourse",
	async () => {
		const querySnapshot = await getDocs(
			collection(db, "/documents/1647607837012/allDocuments")
		);

		let documents: any = [];

		querySnapshot.forEach((doc) => {
			documents.push(doc.data());
		});
		return documents;
	}
);

export const actionCreateDocument = createAsyncThunk(
	"documents/createDocument",
	async (data: any) => {
		const storage = getStorage();
		// Nombre con - en lugar de espacios
		const formattedName = data.documentName.replaceAll(" ", "-");
		const storageRef = ref(
			storage,
			`documents/${data.courseId}/${formattedName}`
		);
		const documentId = GenerateUid();
		uploadBytes(storageRef, data.documentFile).then((snapshot) => {
			getDownloadURL(snapshot.ref).then(async (documentUrl) => {
				await setDoc(
					doc(
						db,
						`documents/1647607837012/allDocuments/${documentId}`
					),
					{
						name: data.documentName,
						documentUrl: documentUrl,
					}
				);
			});
		});
	}
);

// export const actionGetAllCourses = createAsyncThunk(
// 	"courses/getAllCourses",
// 	async () => {
// 		const querySnapshot = await getDocs(collection(db, "courses"));

// 		let courses: any = [];

// 		querySnapshot.forEach((doc) => {
// 			courses.push(doc.data());
// 		});
// 		return courses;
// 	}
// );

// export const actionActivateCourse = createAsyncThunk(
// 	"courses/activateCourse",
// 	async ({
// 		userId,
// 		courseId,
// 		courseName,
// 		courseDescription,
// 		courseImage,
// 	}: {
// 		userId: string | undefined;
// 		courseId: string;
// 		courseName: string;
// 		courseDescription: string;
// 		courseImage: string;
// 	}) => {
// 		await setDoc(doc(db, `users/${userId}/courses/${courseId}`), {
// 			courseName: courseName,
// 			courseId: courseId,
// 			courseDescription: courseDescription,
// 			courseImage: courseImage,
// 		});
// 	}
// );

// export const actionDeactivateCourse = createAsyncThunk(
// 	"courses/deactivateCourse",
// 	async ({
// 		userId,
// 		courseId,
// 	}: {
// 		userId: string | undefined;
// 		courseId: string;
// 	}) => {
// 		await deleteDoc(doc(db, `users/${userId}/courses/${courseId}`));
// 	}
// );
