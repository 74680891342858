import React from "react";
import { HiX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const AdminSidebar = ({
  setIsSidebarOpen,
  isSidebarOpen,
}: {
  setIsSidebarOpen: () => void;
  isSidebarOpen: boolean;
}) => {
  const navigation = useNavigate();

  const deleteLocalStorage = () => {
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("user");
  };

  const courseIdPath = window.location.pathname.split("/")[1];

  return (
    <>
      <div
        className={`transition-all duration-500 z-50 fixed top-0 ${
          isSidebarOpen ? "left-0" : "-left-64"
        }`}
      >
        <div className="flex h-screen overflow-y-auto flex-col bg-white shadow-md shadow-black/50 w-64 px-4 py-8 border-r min-h-screen relative">
          <button
            onClick={() => setIsSidebarOpen()}
            className={`absolute top-2 right-2  text-gray-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-gray-300 focus:outline-none ml-6 hover:bg-gray-200 hover:text-gray-800`}
          >
            <HiX className="w-6 h-6" />
          </button>
          <div
            className={`flex flex-col mt-10 items-center h-full ${
              window.location.pathname !== "/home"
                ? "justify-between"
                : "justify-end"
            }`}
          >
            {window.location.pathname !== "/home" && (
              <div>
                <button
                  onClick={() => {
                    navigation(`/admin/home`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Inicio</h1>
                </button>
                <button
                  onClick={() => {
                    navigation(`/admin/users`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Crear usuario</h1>
                </button>
                <button
                  onClick={() => {
                    navigation(`/admin/users-activities`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Lista de Usuarios</h1>
                </button>
                {/* <button
                  onClick={() => {
                    navigation(`/admin/users`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Crear Usuario</h1>
                </button> */}
                <button
                  onClick={() => {
                    navigation(`/admin/create-course`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Crear Curso</h1>
                </button>

                <button
                  onClick={() => {
                    navigation(`/admin/upload-documents`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Subir Documentos</h1>
                </button>
                <button
                  onClick={() => {
                    navigation(`/admin/create-module`);
                    setIsSidebarOpen();
                  }}
                  className="bg-green-300 mb-5 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
                >
                  <h1>Crear Modulo</h1>
                </button>
              </div>
            )}

            <div className="w-full">
              <button
                onClick={() => {
                  const auth = getAuth();
                  signOut(auth);
                  deleteLocalStorage();
                  navigation("/");
                }}
                className="bg-red-400 w-full p-2 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
              >
                <h1>Cerrar sesión</h1>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
