import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { useAppDispatch } from "../../../redux/hooks";
import { actionGetAllCourses } from "../../../services/courses.services";
import { actionCreateModule } from "../../../services/modulos.services";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SyncLoader } from "react-spinners";

const CreateModule = () => {
	const dispatch = useAppDispatch();
	const MySwal = withReactContent(Swal);

	type Inputs = {
		moduleName: string;
		videoUrl: string;
	};

	React.useEffect(() => {
		dispatch(actionGetAllCourses()).then((responseAllCourses) =>
			setAllCourses(responseAllCourses.payload)
		);
	}, []);

	const [allCourses, setAllCourses] = React.useState([]);
	const [imagen, setImagen] = React.useState();
	const [spinnerCreate, setSpinnerCreate] = React.useState(false);

	const changeImagen = (e: any) => {
		setImagen(e.target.files[0]);
	};

	const upload = () => {};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		if (courseSelected === undefined) {
			return MySwal.fire({
				icon: "error",
				title: "Debes seleccionar un curso",
				confirmButtonText: "Ok",
			});
		} else {
			setSpinnerCreate(true);
			dispatch(
				actionCreateModule({
					moduleImage: imagen,
					moduleName: data.moduleName,
					moduleVideo: data.videoUrl,
					moduleQuestions: questionList,
					courseId: courseSelected,
				})
			);
			setTimeout(() => {
				setSpinnerCreate(false);
			}, 3000);
		}
	};

	const [questionList, addQuestionList] = React.useState<string[]>([]);

	const [questionText, setQuestionText] = React.useState("");

	const addQuestion = () => {
		if (questionText !== "") {
			addQuestionList([...questionList, questionText]);
			setQuestionText("");
		}
	};

	const deleteQuestion = (questionDelete: string) => {
		const newQuestionList = questionList.filter(
			(question) => question != questionDelete
		);
		addQuestionList(newQuestionList);
	};

	const [courseSelected, setCourseSelected] = React.useState(undefined);

	const handleChangeSelectCourse = (e: any) => {
		setCourseSelected(e.target.value);
	};

	const hiddenFileInput = React.useRef<HTMLInputElement>(null);
	const handleClick = (event: any) => {
		hiddenFileInput.current!.click();
	};
	const handleChange = (e: any) => {
		setImagen(e.target.files[0]);
	};

	return (
		<div className="pl-10 pt-20 pb-20">
			<span className="flex justify-center font-black text-cyan-700 text-4xl mt-5 mb-10">
				Crear un modulo
			</span>
			<div className="flex justify-center">
				<select
					name="course-select"
					onChange={(e) => {
						handleChangeSelectCourse(e);
					}}
					value={courseSelected}
					className="bg-green-300 h-10 w-80 text-white font-bold text-xl"
				>
					<option value={"empty"}>
						Selecciona un curso
					</option>
					{allCourses.map((course: any, index) => (
						<option key={index} value={course.courseId}>
							{course.courseName}
						</option>
					))}
				</select>
			</div>
			<span className="flex justify-center font-black text-cyan-700 text-2xl mb-5 mt-10">
				Seleccionar una imagen
			</span>
			<div className="h-52 flex mb-5 flex-row items-center justify-center">
				<button
					onClick={handleClick}
					className="w-32 h-32 flex justify-center items-center hover:text-green-500"
				>
					<div className="flex flex-col justify-around items-center">
						<FaUpload size={50} />
						<span className="mt-5">
							Subir una imagen
						</span>
					</div>
				</button>
				<input
					type="file"
					ref={hiddenFileInput}
					onChange={handleChange}
					style={{ display: "none" }}
				/>
				{imagen ? (
					<img
						className="w-52 h-52 border-2 ml-10 border-black rounded-sm"
						src={URL.createObjectURL(imagen)}
					/>
				) : (
					<div className="w-52 h-52 ml-10" />
				)}
			</div>

			<form
				onSubmit={handleSubmit(onSubmit)}
				id="create-module"
				noValidate
			>
				<div className="flex flex-col items-center">
					<input
						{...register("moduleName", {
							required: true,
							// pattern: /^\S+@\S+\.\S+$/,
						})}
						className={
							errors?.moduleName
								? "border-2 w-3/5 h-14 rounded-lg p-2 border-red-500 text-red-500 placeholder-red-500"
								: "border-blue-400 border-2 w-3/5 h-14 rounded-lg p-2"
						}
						placeholder="Nombre Modulo"
					/>
					{errors?.moduleName?.type === "required" ? (
						<div className="h-8 mt-2 flex items-center ">
							<span>Este campo es requerido</span>
						</div>
					) : (
						<div className="h-8" />
					)}

					<input
						{...register("videoUrl", {
							required: true,
							// pattern: /^\S+@\S+\.\S+$/,
						})}
						className={
							errors?.videoUrl
								? "border-2 w-3/5 h-14 rounded-lg p-2 border-red-500 text-red-500 placeholder-red-500"
								: "border-blue-400 border-2 w-3/5 h-14 rounded-lg p-2"
						}
						placeholder="Url Video"
					/>
					{errors?.videoUrl?.type === "required" ? (
						<div className="h-8 mt-2 flex items-center ">
							<span>Este campo es requerido</span>
						</div>
					) : (
						<div className="h-8" />
					)}
				</div>
			</form>

			<div className="flex flex-col items-center">
				<span className="flex justify-center font-black text-cyan-700 text-2xl mb-5 mt-10">
					Actividades - Preguntas
				</span>
				<div className="w-full flex justify-center">
					<input
						className={
							"w-1/2 border-blue-400 border-2 h-14 rounded-lg p-2 mr-5"
						}
						placeholder="Pregunta"
						value={questionText}
						onChange={(e) => {
							setQuestionText(e.target.value);
						}}
					/>
					<button
						className="bg-green-300 px-6 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						onClick={() => addQuestion()}
					>
						Agregar
					</button>
				</div>
				{questionList.map((elem, index) => (
					<div
						key={index}
						className="w-full flex flex-col items-center"
					>
						<div className="w-3/4 mt-3 flex items-center justify-between">
							<div className="flex items-center">
								<div className="bg-blue-500 rounded-full p-2 mr-2">
									<span className="text-xl font-bold p-2">
										{index + 1}
									</span>
								</div>
								<span className="text-xl font-bold p-2 mr-2">
									-
								</span>
								<span className="text-xl">
									{elem}
								</span>
							</div>
							<button
								className="bg-red-300 py-2 px-6 rounded-md shadow-lg shadow-red-500/50 text-white font-bold text-xl"
								onClick={() =>
									deleteQuestion(elem)
								}
							>
								Eliminar
							</button>
						</div>
						<div className="border-gray-200 rounded-full mt-3 border-b-2 w-4/5" />
					</div>
				))}
			</div>
			<div className="w-full flex justify-center">
				{spinnerCreate ? (
					<div className="mt-10">
						<SyncLoader
							color="green"
							loading={spinnerCreate}
						/>
					</div>
				) : (
					<button
						className="bg-green-300 py-4 px-6 mt-10 rounded-md shadow-lg shadow-green-500/50 text-white font-bold text-xl"
						type="submit"
						form="create-module"
					>
						Crear modulo
					</button>
				)}
			</div>
		</div>
	);
};

export default CreateModule;
